<template>
  <b-list-group-item
    class="notification-list-item"
    href="#"
    @click.prevent="onClick"
    :key="key"
  >
    <b-media class="align-items-center" no-body>
      <b-media-aside vertical-align="center">
        <b-img
          v-if="notification.image"
          :src="notification.image"
          width="60"
          height="60"
        ></b-img>
        <fa-icon
          v-if="notification.faIcon"
          :icon="notification.faIcon"
          class="notification-fa-icon"
        ></fa-icon>
      </b-media-aside>
      <b-media-body>
        <div>{{ notification.body }}</div>
        <div>
          <small>
            {{ moment.unix(notification.timestamp).format("lll") }} -
            {{ moment.unix(notification.timestamp).fromNow() }}
          </small>
        </div>
      </b-media-body>
      <b-media-aside vertical-align="center" class="text-center">
        <div
          class="mark-as-read"
          @mouseover="icon = 'circle'"
          @mouseout="icon = 'circle-dot'"
          @click.stop.prevent="markAsRead(notification)"
          title="Mark as read"
          v-if="notification.read == null"
        >
          <fa-icon :icon="icon"></fa-icon>
        </div>
      </b-media-aside>
    </b-media>
  </b-list-group-item>
</template>

<script>
export default {
  name: "notification-list-item",

  props: {
    notification: {
      type: Object,
      default: () => ({}),
    },
  },

  data: () => ({
    icon: "circle-dot",
    key: 0,
  }),

  methods: {
    async markAsRead(notification) {
      await this.$store.dispatch("notifications/markAsRead", notification);
      this.key++;
    },

    async onClick() {
      await this.markAsRead(this.notification);
      this.$emit("redirection");
      let target = `/notifications/${this.notification.id}`;
      if (this.notification.redirectTo) target = this.notification.redirectTo;
      this.$router.push(target);
    },
  },
};
</script>

<style scoped>
.notification-fa-icon {
  font-size: 27px;
  margin: 4px 0 0 5px;
}
</style>
