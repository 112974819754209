<template>
  <b-container
    class="mb-5 dashboard-view"
    :class="{ 'py-3': isMobile, 'py-5': !isMobile }"
    fluid="md"
  >
    <DashboardPublicView v-if="!isStaff" />
    <b-card no-body v-if="isStaff">
      <b-tabs card>
        <b-tab :title="lga.lga_name">
          <DashboardStaffView />
        </b-tab>
        <b-tab title="Personal">
          <DashboardPublicView />
        </b-tab>
      </b-tabs>
    </b-card>
  </b-container>
</template>

<script>
import DashboardPublicView from "@/views/DashboardPublicView";
import DashboardStaffView from "@/views/DashboardStaffView";
import { mapGetters } from "vuex";

export default {
  name: "dashboard-dynamic-view",

  metaInfo: {
    title: "Dashboard",
  },

  components: {
    DashboardPublicView,
    DashboardStaffView,
  },

  data: () => ({
    customerHasActiveSubscription: false,
  }),

  computed: {
    ...mapGetters("userData", ["profile", "assignedLGAs"]),

    isStaff() {
      return this.customerHasActiveSubscription && this.assignedLGAs?.length > 0;
    },

    lga() {
      return this.assignedLGAs?.[0] || this.profile?.lga;
    },
  },

  watch: {
    assignedLGAs() {
      const lga = this.assignedLGAs?.[0];
      if (lga) {
        this.refreshCustomer();
      }
    },
  },

  mounted() {
    if (this.assignedLGAs?.[0]) {
      this.refreshCustomer();
    }
  },

  methods: {
    async refreshCustomer() {
      const lgaCode = this.lga?.lga_code;
      this.customerHasActiveSubscription = await this.$store.dispatch(
        "customers/hasActiveSubscription",
        lgaCode
      );
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .tab {
  padding-top: 30px;
}
</style>
