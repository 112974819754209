<template>
  <help-more-info v-bind="$attrs" learn-more-url="/help/lga-management">
    <p>
      When new staff sign in with their council email address they can request access to
      Clozure.
    </p>
    <p>
      Any existing staff member with the 'Account Manager' role can respond. Requests can
      be rejected, accepted as is, or adjusted.
    </p>
  </help-more-info>
</template>

<script>
export default {
  name: "help-pending-access-requests",
};
</script>

<style lang="scss" scoped></style>
