import {
    LayoutPlugin,
    ModalPlugin,
    DropdownPlugin,
    NavPlugin,
    NavbarPlugin,
    ListGroupPlugin,
    FormPlugin,
    FormDatepickerPlugin,
    FormFilePlugin,
    FormInputPlugin,
    FormTimepickerPlugin,
    FormCheckboxPlugin,
    FormTagsPlugin,
    ImagePlugin,
    ToastPlugin,
    OverlayPlugin,
    FormGroupPlugin,
    ButtonPlugin,
    ButtonGroupPlugin,
    ButtonToolbarPlugin,
    LinkPlugin,
    CarouselPlugin,
    CollapsePlugin,
    FormTextareaPlugin,
    TablePlugin,
    CardPlugin,
    BadgePlugin,
    InputGroupPlugin,
    PopoverPlugin,
    TooltipPlugin,
    AlertPlugin,
    TabsPlugin,
    SpinnerPlugin,
} from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import { PaginationPlugin } from 'bootstrap-vue';
import { ProgressPlugin } from 'bootstrap-vue';
import { SkeletonPlugin } from 'bootstrap-vue';
import { FormSelectPlugin } from 'bootstrap-vue';
import DatetimeLocalInput from "@/components/DatetimeLocalInput";
import { MediaPlugin } from 'bootstrap-vue';
import { SidebarPlugin } from 'bootstrap-vue';
import { JumbotronPlugin } from 'bootstrap-vue';

const LoadBootstrapVue = (Vue) => {
    Vue.use(LayoutPlugin)
    Vue.use(ModalPlugin)
    Vue.use(DropdownPlugin)
    Vue.use(NavPlugin)
    Vue.use(NavbarPlugin)
    Vue.use(ListGroupPlugin)
    Vue.use(FormPlugin)
    Vue.use(FormGroupPlugin)
    Vue.use(FormDatepickerPlugin)
    Vue.use(FormFilePlugin)
    Vue.use(FormFilePlugin)
    Vue.use(FormInputPlugin)
    Vue.use(FormTimepickerPlugin)
    Vue.use(FormTextareaPlugin)
    Vue.use(FormTagsPlugin)
    Vue.use(ImagePlugin)
    Vue.use(OverlayPlugin)
    Vue.use(ButtonPlugin)
    Vue.use(ButtonGroupPlugin)
    Vue.use(ButtonToolbarPlugin)
    Vue.use(LinkPlugin)
    Vue.use(CarouselPlugin)
    Vue.use(CollapsePlugin)
    Vue.use(ToastPlugin)
    Vue.use(FormCheckboxPlugin)
    Vue.use(TablePlugin)
    Vue.use(CardPlugin)
    Vue.use(BadgePlugin)
    Vue.use(InputGroupPlugin)
    Vue.use(PopoverPlugin)
    Vue.use(TooltipPlugin)
    Vue.use(AlertPlugin)
    Vue.use(TabsPlugin)
    Vue.use(PaginationPlugin)
    Vue.use(ProgressPlugin)
    Vue.use(SkeletonPlugin)
    Vue.use(FormSelectPlugin)
    Vue.use(SpinnerPlugin)
    Vue.use(MediaPlugin)
    Vue.use(SidebarPlugin)
    Vue.use(JumbotronPlugin)
    Vue.component('datetime-local-input', DatetimeLocalInput);
}

export default LoadBootstrapVue