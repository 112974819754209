<template>
  <DashboardCard>
    <template #title>Closures in {{ lga?.lga_name }}</template>
    <b-list-group flush>
      <b-list-group-item class="item">
        <div class="label">Road Closed</div>
        <b-badge class="badge" variant="danger">{{ closed }}</b-badge>
      </b-list-group-item>
      <b-list-group-item class="item">
        <div class="label">Changed Conditions</div>
        <b-badge class="badge" variant="warning">{{ changed }}</b-badge>
      </b-list-group-item>
      <b-list-group-item class="item">
        <div class="label">Recently Opened</div>
        <b-badge class="badge" variant="success">{{ opened }}</b-badge>
      </b-list-group-item>
      <b-list-group-item class="item">
        <div class="label">
          Scheduled
          <help-more-info learn-more-url="/help/road-conditions">
            <p>
              Closures can be scheduled for a future time, e.g. roadworks and
              construction, public events.
            </p>
            <p>
              These will show on the map with a grey icon, and will update to indicate
              their closed or changed conditions when the scheduled time arrives.
            </p>
          </help-more-info>
        </div>
        <b-badge class="badge" variant="warning">{{ scheduled }}</b-badge>
      </b-list-group-item>
      <b-list-group-item class="item">
        <div class="label">Total</div>
        <b-badge class="badge" variant="info">{{ total }}</b-badge>
      </b-list-group-item>
    </b-list-group>
  </DashboardCard>
</template>

<script>
import DashboardCard from "@/components/DashboardCard";
import { db } from "@/firebase/config";
import { query, where, collection, onSnapshot } from "firebase/firestore";
import moment from "moment";

export default {
  name: "dashboard-card-lga-closure-summary",

  components: {
    DashboardCard,
  },

  mixins: [DashboardCard],

  props: {
    lga: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    closed: 0,
    changed: 0,
    opened: 0,
    scheduled: 0,
  }),

  computed: {
    total() {
      return this.closed + this.changed + this.opened + this.scheduled;
    },
  },

  methods: {
    subscribe() {
      this.scheduled = 0;
      this.unsubs.push(
        this.subToConditionCount("closed"),
        this.subToConditionCount("changed"),
        this.subToConditionCount("opened")
      );
    },

    subToConditionCount(condition) {
      return onSnapshot(
        query(
          collection(db, "closures"),
          where("lga.lga_code", "==", this.lgaCode),
          where("active", "==", true),
          where("condition", "==", condition)
        ),
        (snapshot) => {
          console.debug(
            "[DashboardCardLgaClosureSummary] subToConditionCount onSnap",
            snapshot
          );

          const allItems = snapshot.docs.map((docSnap) => docSnap.data());

          this[condition] = allItems.filter(
            (c) => c.scheduledFor <= moment().unix()
          ).length;

          this.scheduled += allItems.filter(
            (c) => c.scheduledFor > moment().unix()
          ).length;

          // this[condition] = snapshot.size;
        },
        (e) => {
          this.errorToast(`Failed to subscribe to ${condition} count: ${e.message}`);
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem;

  .label {
    color: #212529;
    font-size: 0.9rem;
    font-weight: lighter;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
  }

  .badge {
    font-size: 0.9rem;
    padding: 6px 11px;
  }
}
</style>
