<script>
export default {
  name: "closure-reopening-mixin",

  methods: {
    async onReopen(closure, confirmed = false) {
      if (!confirmed) {
        const confirmationMsg = `Are you sure you want to reopen this closure? 
          Users will see this road as opened and the incident will be archived 
          and removed from the map after 24 hours.`;

        confirmed = await this.$bvModal.msgBoxConfirm(confirmationMsg, {
          centered: true,
        });
      }

      if (confirmed) {
        try {
          await this.$store.dispatch("closureWrite/reopen", closure.id);
        } catch (e) {
          this.errorToast(`Failed to reopen the closure: ${e.message}`);
          return;
        }

        if (this.notification) {
          const payload = {
            notificationId: this.notification.id,
            closureId: closure.id,
          };

          try {
            await this.$store.dispatch("notifications/markReminderAsActioned", payload);
          } catch (e) {
            this.errorToast(e.message);
          }
        }
      }
    },

    async onReopenMultiple(closures) {
      // already confirmed within the modal
      await Promise.allSettled(closures.map((c) => this.onReopen(c, true)));
    },
  },
};
</script>
