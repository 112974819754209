import { auth, db } from "@/firebase/config";
import {
  createUserWithEmailAndPassword,
  FacebookAuthProvider,
  fetchSignInMethodsForEmail,
  GoogleAuthProvider,
  linkWithCredential,
  OAuthProvider,
  signInWithEmailAndPassword,
  // signInWithPopup,
  signOut,
  updateProfile,
  getAdditionalUserInfo,
  deleteUser,
  getRedirectResult,
  signInWithRedirect,
} from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";

export default {
  namespaced: true,

  state: () => ({
    user: null,
    staff: false,
    admin: false,
  }),

  mutations: {
    SET_USER(state, user) {
      state.user = user;
    },
    SET_STAFF(state, payload) {
      state.staff = payload;
    },
    TOGGLE_STAFF(state) {
      state.staff = !state.staff;
    },
    SET_ADMIN(state, payload) {
      state.admin = payload;
    },
  },

  actions: {
    async setStaff({ commit, getters }, payload) {
      const docRef = doc(db, "users", getters["user"].uid);
      await setDoc(docRef, { staff: payload }, { merge: true });
      commit("SET_STAFF", payload);
    },

    async setUser(context, user) {
      if (context.state.unsub) context.state.unsub();
      if (user) {
        const unsub = await context.dispatch("userData/loadUserData", user.uid, { root: true });
        context.state.unsub = unsub;
      } else {
        context.commit("SET_STAFF", false);
        context.commit("SET_ADMIN", false);
      }
      context.commit("SET_USER", user);
    },

    async signup(context, { displayName, email, password }) {
      const result = await createUserWithEmailAndPassword(auth, email, password);
      await updateProfile(result.user, { displayName });
      result.user.displayName = displayName;
      await context.dispatch("handleNewUserLogin", result);
      await context.dispatch("setUser", result.user);
    },

    async handleNewUserLogin(context, result) {
      // check if user is new and set a default profile if so
      const additionalUserInfo = getAdditionalUserInfo(result);
      if (additionalUserInfo.isNewUser) {
        const profile = { displayName: result.user.displayName, email: result.user.email };
        await context.dispatch("userData/saveProfile",
          { userId: result.user.uid, profile },
          { root: true }
        );
      }
      return additionalUserInfo;
    },

    async login(context, { email, password }) {
      const result = await signInWithEmailAndPassword(auth, email, password);
      await context.dispatch("setUser", result.user);
      await context.dispatch("handleNewUserLogin", result);
      return result.user;
    },

    // async providerLogin(context, { providerClass, providerInstance }) {
    //   providerInstance.setCustomParameters({
    //     prompt: "select_account",
    //   });
    //   try {
    //     const result = await signInWithPopup(auth, providerInstance);
    //     const credential = providerClass.credentialFromResult(result);
    //     const user = result.user;
    //     user.token = credential.accessToken;
    //     await context.dispatch("setUser", user);
    //     return context.dispatch("handleNewUserLogin", result);
    //   } catch (e) {
    //     if (e.code === "auth/account-exists-with-different-credential") {
    //       const pendingCredential = providerClass.credentialFromError(e);
    //       const email = e.customData.email;
    //       await context.dispatch("linkProviders", { email, pendingCredential });
    //       return getAdditionalUserInfo(pendingCredential);
    //     } else if (e.code == "auth/popup-closed-by-user") {
    //       throw new Error("Oops! Something went wrong. Please try again.");
    //     }
    //     throw e;
    //   }
    // },

    providerRedirectLogin(context, { providerInstance }) {
      signInWithRedirect(auth, providerInstance);
    },

    async checkAuthRedirect(context) {
      try {
        const response = await getRedirectResult(auth);
        console.debug("[auth] checkAuthRedirect", response);

        if (response) {
          // if cred linking was in progress, continue it here
          await checkOngoingCredentialLinkingProcess(response);

          // process the user sign-in and get additional user info
          await context.dispatch("setUser", response.user);
          const additionalUserInfo = await context.dispatch("handleNewUserLogin", response);

          return additionalUserInfo;
        }
      } catch (e) {
        if (e.code === "auth/account-exists-with-different-credential") {
          await linkCredentialsToExistingAccount(e)
        }
        throw e;
      }
    },

    appleLogin(context) {
      return context.dispatch("providerLogin", {
        providerClass: OAuthProvider,
        providerInstance: new OAuthProvider("apple.com"),
      });
    },

    microsoftLogin(context) {
      return context.dispatch("providerRedirectLogin", {
        providerClass: OAuthProvider,
        providerInstance: new OAuthProvider("microsoft.com"),
      });
    },

    googleLogin(context) {
      return context.dispatch("providerRedirectLogin", {
        providerClass: GoogleAuthProvider,
        providerInstance: new GoogleAuthProvider(),
      });
    },

    facebookLogin(context) {
      return context.dispatch("providerRedirectLogin", {
        providerClass: FacebookAuthProvider,
        providerInstance: new FacebookAuthProvider(),
      });
    },

    // async linkProviders(context, payload) {
    //   const { email, pendingCredential } = payload;
    //   const signInMethods = await fetchSignInMethodsForEmail(auth, email);
    //   let method = signInMethods[0];
    //   let provider;
    //   let providerClass;

    //   switch (method) {
    //     case "google.com":
    //       provider = new GoogleAuthProvider();
    //       providerClass = GoogleAuthProvider;
    //       break;
    //     case "microsoft.com":
    //       provider = new OAuthProvider("microsoft.com");
    //       providerClass = OAuthProvider;
    //       break;
    //     case "facebook.com":
    //       provider = new FacebookAuthProvider();
    //       providerClass = FacebookAuthProvider;
    //       break;
    //   }

    //   const result = await signInWithPopup(auth, provider);
    //   const linkResult = await linkWithCredential(result.user, pendingCredential);
    //   const linkCredential = providerClass.credentialFromResult(linkResult);
    //   const user = linkResult.user;
    //   user.token = linkCredential.accessToken;
    //   context.dispatch("setUser", user);
    //   return user;
    // },

    async logout({ state, dispatch }) {
      if (state.unsub) state.unsub();
      await dispatch("notifications/unsubscribe", null, { root: true });
      await signOut(auth);
      await dispatch("setUser", null);
      await dispatch("clearAppState", null, { root: true });
    },

    async confirmDeleteUser(context) {
      // collate all docRefs to remove from db
      // delete all unapproved incident reports and related media
      // delete userData from "users"
      // delete the actual user from firebase
      await deleteUser(auth.currentUser);
      this.infoToast(`Your account has been removed successfully`, {
        title: "Sorry to see you go",
      });
      await context.dispatch("logout");
    },

    async getCustomClaims() {
      try {
        const { claims } = await auth.currentUser.getIdTokenResult(true);
        return claims;
      } catch (e) {
        return {};
      }
    },
  },

  getters: {
    user(state) {
      return state.user;
    },
    authed(state) {
      return state.user != null;
    },
    staff(state) {
      return state.user && state.staff;
    },
    guest(state) {
      return state.user && !state.staff;
    },
    admin(state) {
      return state.user && state.admin;
    },
    userType(state) {
      if (!state.user) return "anonymous";
      return state.admin ? "administrator" : state.staff ? "staff" : "guest";
    },
    serialisedUser(state) {
      const { uid, displayName, email, staff, admin } = state.user || {};
      return { uid, displayName, email, staff: staff == true, admin: admin == true };
    },
    emailSuffix(state) {
      const { email } = state.user;
      if (!email) return null;
      return email.split("@")[1];
    },
  },
};

async function linkCredentialsToExistingAccount(e) {
  const pendingCredential = OAuthProvider.credentialFromError(e);
  const email = e.customData.email;
  console.debug("[auth] linkCredentialsToExistingAccount", email, pendingCredential);

  // store the pendingCredential in localStorage 
  localStorage.setItem("pendingCredential", JSON.stringify(pendingCredential));

  // signInWithRedirect on the user's existing method
  const signInMethods = await fetchSignInMethodsForEmail(auth, email);
  let method = signInMethods[0];
  let provider;

  switch (method) {
    case "google.com":
      provider = new GoogleAuthProvider();
      break;
    case "microsoft.com":
      provider = new OAuthProvider("microsoft.com");
      break;
    case "facebook.com":
      provider = new FacebookAuthProvider();
      break;
  }

  return signInWithRedirect(auth, provider);
}

async function checkOngoingCredentialLinkingProcess(response) {
  // check for an existing pendingCredential in localStorage
  const pendingCredentialJSON = localStorage.getItem("pendingCredential");

  if (pendingCredentialJSON) {
    console.debug("[auth] checkOngoingCredentialLinkingProcess", "linking credentials");
    try {
      const pendingCredential = OAuthProvider.credentialFromJSON(pendingCredentialJSON);
      await linkWithCredential(response.user, pendingCredential);
    } catch (e) {
      // do nothing
      console.error(`Failed to link credentials: ${e.message}`);
    } finally {
      localStorage.removeItem("pendingCredential");
    }
  }
}