<template>
  <DashboardCard>
    <template #title>
      Reopening upcoming or overdue
      <HelpClosureEstimatedReopening triggers="click blur" />
    </template>
    <template #header-right>
      <b-badge variant="primary">
        {{ totalOverdue }}
      </b-badge>
    </template>
    <b-list-group flush>
      <b-list-group-item
        v-for="(closure, i) of closures"
        :key="i"
        :to="`/closures/${closure.id}`"
        class="d-flex justify-content-between align-items-center"
        :variant="variant(closure)"
      >
        <div>
          <b-img
            width="60"
            height="60"
            class="mr-2"
            style="margin-left: -5px"
            :src="`/img/markers/64x64/marker_${closure.type.value}_${closure.condition}.png`"
          />
        </div>
        <div :class="{ 'text-should-wrap': !isMobile }">
          {{ closure.nearestLocation.name }} {{ closure.type.text }}
          <br />
          <small class="text-muted">
            {{ closure.formattedAddress }}
          </small>
        </div>
        <div class="d-flex flex-grow-1"></div>
        <div v-if="!isMobile" class="flex-shrink-0">
          <TimestampTableEntry
            :timestamp="closure.estimatedReopening"
            class="text-right"
          />
        </div>
        <div class="ml-4">
          <b-button variant="outline-primary" @click.stop.prevent="onReopen(closure)">
            Reopen
          </b-button>
        </div>
      </b-list-group-item>
      <b-list-group-item v-if="closures?.length === 0">
        There are no closures overdue for reopening.
      </b-list-group-item>
      <b-list-group-item class="text-center text-muted" to="/closures/reopening-overdue">
        <span v-if="totalOverdue > 5">View All</span>
        <span v-else>Administer Closures</span>
      </b-list-group-item>
    </b-list-group>
  </DashboardCard>
</template>

<script>
import DashboardCard from "@/components/DashboardCard";
import TimestampTableEntry from "@/components/table-cells/TimestampTableEntry";
import DashboardStaffCard from "@/mixins/DashboardStaffCard";
import ClosureReopeningMixin from "@/mixins/ClosureReopeningMixin";
import { db } from "@/firebase/config";
import {
  query,
  where,
  collection,
  onSnapshot,
  orderBy,
  limit,
  getCountFromServer,
} from "firebase/firestore";
import { manyFromSnapshot } from "@/firebase/firestore-helpers";
import moment from "moment";
import HelpClosureEstimatedReopening from "@/components/HelpClosureEstimatedReopening";

export default {
  name: "dashboard-card-lga-overdue-closures",

  components: {
    DashboardCard,
    TimestampTableEntry,
    HelpClosureEstimatedReopening,
  },

  mixins: [DashboardCard, DashboardStaffCard, ClosureReopeningMixin],

  data: () => ({
    closures: [],
    totalOverdue: 0,
  }),

  computed: {},

  methods: {
    subscribe() {
      this.unsubs.push(
        onSnapshot(
          query(
            collection(db, "closures"),
            where("lga.lga_code", "==", this.lgaCode),
            where("active", "==", true),
            where("estimatedReopening", "<=", moment().endOf("day").unix()),
            orderBy("estimatedReopening", "asc"),
            limit(5)
          ),
          (snapshot) => {
            this.closures = manyFromSnapshot(snapshot);
            this.refreshCount();
          },
          (e) => {
            this.errorToast(`Failed to load overdue closures: ${e.message}`);
          }
        )
      );
    },

    async refreshCount() {
      const q = query(
        collection(db, "closures"),
        where("lga.lga_code", "==", this.lgaCode),
        where("active", "==", true),
        where("estimatedReopening", "<=", moment().unix())
      );
      try {
        const snapshot = await getCountFromServer(q);
        this.totalOverdue = snapshot.data().count;
      } catch (e) {
        this.totalOverdue = 0;
        console.error(`Failed to refresh total count of overdue closures: ${e.message}`);
      }
    },

    variant(closure) {
      return moment().unix() > closure.estimatedReopening ? "warning" : "default";
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem;

  .label {
    color: #212529;
    font-size: 0.9rem;
    font-weight: lighter;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
  }

  .badge {
    font-size: 0.9rem;
    padding: 6px 11px;
  }
}
</style>
