import { GeometryFactory, Coordinate } from "jsts/org/locationtech/jts/geom"
import { BufferOp } from 'jsts/org/locationtech/jts/operation/buffer';

export function thickenPolyline(line, thickness) {
    const factory = new GeometryFactory();
    var distance = (thickness / 1000) / 111.12;
    var jstsLine = line.map((point) => {
        return new Coordinate(point.lat, point.lng);
    });
    var shell = factory.createLineString(jstsLine)
    var polygon = BufferOp.bufferOp(shell, distance)
    const coords = polygon.getCoordinates();
    return coords.map(({ x, y }) => ({ lat: x, lng: y }));
}


export function bufferLatLngBounds(bounds, bufferSizeMetres) {
    const { north, south, east, west } = bounds.toJSON();

    let shell = [
        new Coordinate(north, west),
        new Coordinate(north, east),
        new Coordinate(south, east),
        new Coordinate(south, west),
        new Coordinate(north, west),
    ];

    const factory = new GeometryFactory();
    let polygon = factory.createPolygon(shell);

    let distance = (bufferSizeMetres / 1000) / 111.12;
    let buffered = BufferOp.bufferOp(polygon, distance);

    return buffered
        .getBoundary()
        .getCoordinates()
        .map(({ x, y }) => ({ lat: x, lng: y }));
}
