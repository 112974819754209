<template>
  <DashboardCard v-show="pwaDeferredPromptAvailable">
    <template #title>Install Clozure</template>
    <b-card-body>
      <p>
        Clozure is a
        <a href="https://en.wikipedia.org/wiki/Progressive_web_app" target="_blank">
          progressive web app</a
        >, which allows you to install it to your device and use it offline.
      </p>
      <b-button
        variant="outline-primary"
        @click="!isDemo && installPWA()"
        id="btn-card-install-pwa"
        :disabled="pwaInstalled"
      >
        {{
          pwaInstalled ? "Clozure is already installed" : "Install Clozure on this device"
        }}
      </b-button>
      <b-tooltip target="btn-card-install-pwa" triggers="hover focus" :disabled="!isDemo">
        Disabled in the demo
      </b-tooltip>
    </b-card-body>
  </DashboardCard>
</template>

<script>
import DashboardCard from "@/components/DashboardCard";
import PWAInstall from "@/mixins/PWAInstall";

export default {
  name: "dashboard-card-install-pwa",

  mixins: [DashboardCard, PWAInstall],

  components: {
    DashboardCard,
  },
};
</script>

<style lang="scss" scoped></style>
