
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { indexedDBLocalPersistence, getAuth, setPersistence } from "firebase/auth";
import { enableMultiTabIndexedDbPersistence, getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";
import { getFunctions } from "firebase/functions";
import { getToken, deleteToken, getMessaging } from "firebase/messaging";
import { getPerformance } from "firebase/performance";
import { connectFunctionsEmulator } from "firebase/functions";
import { connectAuthEmulator } from "firebase/auth";
import { connectFirestoreEmulator } from "firebase/firestore";
import { connectStorageEmulator } from "firebase/storage";

const fcmConfig = {
  vapidKey: process.env.VUE_APP_FIREBASE_VAPID_KEY
};

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
}

const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)
export const db = getFirestore(app)
export const storage = getStorage(app)
export const analytics = getAnalytics(app)
export const functions = getFunctions(app, "australia-southeast1")
export const messaging = getMessaging(app);
export const performance = getPerformance(app);
export const getFCMToken = () => getToken(messaging, fcmConfig);
export const deleteFCMToken = () => deleteToken(messaging);

// enable local emulators
if (process.env.VUE_APP_EMULATED) {
  console.debug("connecting emulators…");
  connectAuthEmulator(auth, "http://localhost:9099")
  connectFirestoreEmulator(db, "localhost", 8080)
  connectFunctionsEmulator(functions, "localhost", 5001)
  connectStorageEmulator(storage, "localhost", 9199)
}
if (process.env.VUE_APP_FUNCTIONS_EMULATED) {
  console.debug("connecting functions emulator on port 5001…");
  connectFunctionsEmulator(functions, "localhost", 5001);
}

setPersistence(auth, indexedDBLocalPersistence)
  .then(() => console.debug('auth persistence set'))
  .catch((e) => console.error(`auth persistence failed: ${e.code}: ${e.message}`));

enableMultiTabIndexedDbPersistence(db)
  .then(() => console.debug('firestore persistence set'))
  .catch((e) => console.error(`firestore persistence failed: ${e.code}: ${e.message}`));