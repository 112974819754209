<template>
  <div class="help-more-info">
    <b-button :variant="variant" class="help-more-info-button" :id="id">
      <fa-icon icon="circle-question"></fa-icon>
    </b-button>
    <b-popover
      :target="id"
      :triggers="computedTriggers"
      :show.sync="show"
      :placement="placement"
      v-bind="$attrs"
      custom-class="help-more-info-popover"
    >
      <b-button-close class="ml-2 mb-2" @click="show = false" />
      <slot></slot>
      <slot name="learn-more">
        <b-link
          class="mt-3 learn-more-link"
          v-if="learnMoreUrl && !disableLink"
          :to="learnMoreUrl"
        >
          <slot name="learn-more-text">
            LEARN MORE
            <fa-icon icon="arrow-up-right-from-square"></fa-icon>
          </slot>
        </b-link>
      </slot>
    </b-popover>
  </div>
</template>

<script>
import { v4 as uuid } from "uuid";

export default {
  name: "help-more-info",

  props: {
    id: {
      type: String,
      default: () => uuid(),
    },
    learnMoreUrl: {
      type: [String, undefined],
      default: undefined,
    },
    triggers: {
      type: [String, undefined],
      default: undefined,
    },
    placement: {
      type: String,
      default: "bottom",
    },
    variant: {
      type: String,
      default: "light",
    },
    disableLink: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    show: false,
  }),

  computed: {
    computedTriggers() {
      if (this.triggers) return this.triggers;
      return this.isMobile ? "click blur" : "hover";
    },
  },
};
</script>

<style lang="scss" scoped>
.help-more-info {
  margin-left: 5px;
  display: inline-block;
  transform: translateY(-3px);
}

.help-more-info-button {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding: 0;

  .fa-circle-question {
    transform: translate(0px, -2.25px);
  }
}
.learn-more-link {
  text-decoration: none;
}
</style>
<style>
.help-more-info-popover {
  max-width: none;
}
.help-more-info-popover,
.help-more-info-popover .popover-body {
  width: min(400px, 75vw);
}
</style>
