import { query, where, onSnapshot, collection, doc, updateDoc, arrayRemove, setDoc, serverTimestamp } from "firebase/firestore";
import { oneFromSnapshot, manyFromSnapshot } from "@/firebase/firestore-helpers";
import { db } from "@/firebase/config";
import { reverseGeocode } from "@/google/geocode";
import { geohashForLocation } from "geofire-common";
import { latLngToArray, latLngToLiteral } from "@/util";
import moment from "moment";

const state = {
    clusters: [],
    activeCluster: null,
    closure: {},
};

const mutations = {
    SET_CLUSTERS(state, payload) {
        state.clusters = payload;
    },
    ADD_CLUSTER(state, payload) {
        state.clusters.push(payload);
    },
    UPDATE_CLUSTER(state, payload) {
        const index = state.clusters.findIndex((c) => c.id === payload.id);
        if (index === -1) return;
        state.clusters.splice(index, 1, payload);
    },
    REMOVE_CLOSURE(state, payload) {
        const index = state.clusters.findIndex((c) => c.id === payload.id);
        if (index === -1) return;
        state.clusters.splice(index, 1);
    },
    SET_ACTIVE_CLUSTER(state, payload) {
        state.activeCluster = payload;
    },
};

const actions = {
    subscribeToLGA(context, lgaCode) {
        const colRef = collection(db, "incident_report_clusters");
        const q = query(colRef, where("lga.lga_code", "==", lgaCode));
        const onSnap = (snapshot) => {
            const clusters = manyFromSnapshot(snapshot);
            context.commit("SET_CLUSTERS", clusters);
        };
        const onError = (e) => {
            console.error(`Error subscribing to LGA incident report clusters: ${e.message}`);
        };
        return onSnapshot(q, onSnap, onError);
    },

    subscribeToCluster(context, clusterId) {
        const docRef = doc(db, "incident_report_clusters", clusterId);
        const onSnap = (snapshot) => {
            const cluster = oneFromSnapshot(snapshot);
            context.commit("SET_ACTIVE_CLUSTER", cluster);
        }
        const onError = (e) => {
            console.error(`Error subscribing to report cluster ${clusterId}: ${e.message}`);
        };
        return onSnapshot(docRef, onSnap, onError);
    },

    async setRadius(context, { clusterId, radius }) {
        console.debug("[incident-report-clusters] setRadius", { clusterId, radius });
        const docRef = doc(db, "incident_report_clusters", clusterId);
        await updateDoc(docRef, { radius, updatedAt: moment().unix() });
    },

    async setCenter(context, { clusterId, center }) {
        console.debug("[incident-report-clusters] setCenter", { clusterId, center });
        const docRef = doc(db, "incident_report_clusters", clusterId);
        // latLngToArray gives a [lng,lat] array. a geopoint is a [lat,lng] array
        // so we need to reverse it for the geofire library to hash it
        const geohash = geohashForLocation(latLngToArray(center).reverse())
        const response = await reverseGeocode(latLngToLiteral(center));
        const formattedAddress = response.results[0]?.formatted_address;
        await updateDoc(docRef, { center, geohash, formattedAddress, updatedAt: moment().unix() });
    },

    async removeMedia(context, { clusterId, item }) {
        console.debug(`[incident-report-clusters] removeMedia`, { clusterId, item });
        const docRef = doc(db, "incident_report_clusters", clusterId);
        await updateDoc(docRef, { media: arrayRemove(item) });
    },

    async finaliseConversionToClosure(context, cluster) {
        const closure = {
            ...context.state.closure,
            active: true,
            lga: cluster.lga,
            nearestLocation: await context.dispatch("closureWrite/nearestLocation", context.state.closure.position, { root: true }),
            formattedAddress: await context.dispatch("closureWrite/formattedAddressFromLatLng", context.state.closure.position, { root: true }),
            clusterId: cluster.id,
            source: {
                type: "Incident Report Cluster",
                payload: context.rootGetters["auth/serialisedUser"],
            },
            createdAt: moment().unix(),
        };
        const closureId = await context.dispatch("closureWrite/createFromCluster", closure, { root: true });

        const approvedAt = moment().unix();

        const docRef = doc(db, "incident_report_clusters", cluster.id);
        await updateDoc(docRef, { closureId, approvedAt, status: "approved" });

        // add references for the approved media
        await Promise.all((closure.media || []).map((item) => {
            const payload = {
                mediaId: item.id,
                collectionName: "closures",
                documentId: closureId,
                field: "media",
            }
            return context.dispatch("media/addReference", payload, { root: true });
        }));

        // mark the submissions as approved
        await Promise.all(Object.keys(cluster.submissions).map((reportId) => {
            console.debug("[incident-report-clusters] reportId", reportId);
            const docRef = doc(db, "incident_reports", reportId);
            return setDoc(docRef, { approvedAt, status: "approved" }, { merge: true });
        }));

        context.state.closure = {};
        return closureId;
    },

    async rejectCluster(context, { cluster, reason }) {
        const clusterId = cluster.id;
        const docRef = doc(db, "incident_report_clusters", clusterId);
        await updateDoc(docRef, {
            reason,
            status: "rejected",
            rejectedAt: serverTimestamp(),
        });

        // mark the submissions as 'rejected'
        await Promise.all(
            Object.keys(cluster.submissions)
                .map((reportId) => {
                    console.debug("[incident-report-clusters] reportId", reportId);
                    const docRef = doc(db, "incident_reports", reportId);
                    return setDoc(docRef, { reason, rejectedAt: serverTimestamp(), status: "rejected" }, { merge: true });
                }));
    }
};

const getters = {
    clusters: (state) => state.clusters,
    activeCluster: (state) => state.activeCluster,
};

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced: true,
};
