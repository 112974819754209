<template>
  <help-more-info v-bind="$attrs" learn-more-url="/help/closure-administration">
    <p>
      Staff are able to set an estimated reopening date and time on closures. This can be
      updated at any time.
    </p>

    <p>
      Each morning, a reminder notification will be generated that will notify all
      relevant staff of the closures that are estimated to reopend that day. Closures that
      are overdue for reopening are also provided.
    </p>

    <p>
      <small><strong>PLEASE NOTE:</strong></small> Clozure does not automatically reopen
      closures when this time passes. A staff member is required to perform the reopening
      operation to ensure that no mistakes are made through automation.
    </p>
  </help-more-info>
</template>

<script>
export default {
  name: "help-closure-estimated-reopening",
};
</script>

<style lang="scss" scoped></style>
