import { db } from "@/firebase/config";
import { collection, doc, getDoc, getDocs, getDocsFromCache, onSnapshot, query, where } from "firebase/firestore";
import { manyFromSnapshot, oneFromSnapshot } from "@/firebase/firestore-helpers";
import moment from "moment";
import Vue from "vue";

export default {
  namespaced: true,

  state: {
    activeClosure: null,
    activeClosureSub: null,
    closures: [],
    lgaSubscriptions: {},
    loading: false,
    lastLoad: null,
  },

  mutations: {
    SET_LOADING(state, loading) {
      state.loading = Boolean(loading);
      if (!loading) {
        state.lastLoad = moment().unix();
      }
    },

    SET_ACTIVE_CLOSURE(state, closure) {
      state.activeClosure = closure
    },

    SET_CLOSURES(state, closures) {
      Vue.set(state, "closures", closures);
    },

    MERGE_CLOSURES(state, closures) {
      // upsert each closure into the `closures` array
      closures.forEach((c) => {
        let index = state.closures.findIndex((closure) => closure.id == c.id);
        if (index > -1) {
          state.closures.splice(index, 1, c);
        } else {
          state.closures.push(c);
        }
      });
    },

    STRIP_LGA(state, lgaCode) {
      const keepers = [];
      state.closures.forEach((c) => {
        if (c.lga.lga_code != lgaCode) keepers.push(c);
      });
      state.closures = keepers;
    },

    SET_LGA_SUBSCRIPTION(state, { lgaCode, unsubFn }) {
      if (!lgaCode || !unsubFn) return;
      if (state.lgaSubscriptions[lgaCode]) {
        state.lgaSubscriptions[lgaCode]();
      }
      state.lgaSubscriptions[lgaCode] = unsubFn;
    },

    REMOVE_CLOSURE(state, closureId) {
      const index = state.closures.findIndex((c) => c.id == closureId);
      if (index == -1) return;
      state.closures.splice(index, 1);
    },

    CLEAR_CLOSURES(state) {
      state.closures = [];
    },
  },

  actions: {

    async subscribeToClosures({ commit }) {
      const onSnap = (snapshot) => {
        commit("SET_CLOSURES", manyFromSnapshot(snapshot));
      }
      const onError = (e) => {
        console.error(`Failed to subscribe to closures: ${e.message}`);
      }
      const colRef = collection(db, "closures");
      const q = query(colRef, where("active", "==", true));
      return onSnapshot(q, onSnap, onError);
    },

    async subscribeToSelectedLGAs({ state }, lgaCodes) {
      const onSnap = (snapshot) => {
        const closures = manyFromSnapshot(snapshot);
        state.closures = closures;
      }
      const onError = (e) => {
        console.error(`Failed to subscribe to closures for LGAs: ${lgaCodes.join(', ')} with error: ${e.message}`);
      }
      const q = query(collection(db, "closures"), where("lga.lga_code", "in", lgaCodes), where("active", "==", true));
      return onSnapshot(q, onSnap, onError);
    },

    async subscribeToSingleLGA(context, lgaCode) {
      const onSnap = (snapshot) => {
        context.commit("MERGE_CLOSURES", manyFromSnapshot(snapshot));
      }
      const onError = (e) => {
        console.error(`Error subscribing to single LGA: ${e.message}`);
      }
      const q = query(collection(db, "closures"), where("lga.lga_code", "==", lgaCode), where("active", "==", true));
      return onSnapshot(q, onSnap, onError);
    },

    async loadInactiveByLGA(context, lgaCode) {
      const q = query(collection(db, "closures"), where("lga.lga_code", "==", lgaCode), where("active", "==", false));
      const snapshot = await getDocs(q);
      return manyFromSnapshot(snapshot);
    },

    async loadRecentlyReopenedByLGA(context, lgaCode) {
      const q = query(
        collection(db, "closures"),
        where("lga.lga_code", "==", lgaCode),
        where("active", "==", true),
        where("condition", "==", "opened")
      );
      const snapshot = await getDocs(q);
      return manyFromSnapshot(snapshot);
    },

    async loadDeletedByLGA(context, lgaCode) {
      const q = query(
        collection(db, "closures"),
        where("lga.lga_code", "==", lgaCode),
        where("deletedAt", "!=", null),
        where("archivedAt", "==", null),
      );
      const snapshot = await getDocs(q);
      return manyFromSnapshot(snapshot);
    },

    async loadSingleLGA(context, lgaCode) {
      const q = query(collection(db, "closures"), where("lga.lga_code", "==", lgaCode), where("active", "==", true));
      const snapshot = await getDocs(q);
      const closures = manyFromSnapshot(snapshot);
      context.state.closures = closures;
    },

    async loadAllFromCache() {
      const q = query(collection(db, "closures"));
      const snapshot = await getDocsFromCache(q);
      const closures = manyFromSnapshot(snapshot);
      return closures;
    },

    removeClosure({ commit }, closureId) {
      commit("REMOVE_CLOSURE", closureId);
    },

    clearClosures({ commit }) {
      commit("CLEAR_CLOSURES");
    },

    async loadSingleClosure(context, id) {
      const docRef = doc(db, "closures", id);
      const snapshot = await getDoc(docRef);
      if (!snapshot.exists()) return null;
      return oneFromSnapshot(snapshot)
    },

    async subscribeToSingleClosure({ commit }, id) {
      const docRef = doc(db, "closures", id);
      return onSnapshot(docRef, (snapshot) => commit("SET_ACTIVE_CLOSURE", oneFromSnapshot(snapshot)));
    },

    async onReopenClosure({ state, commit }, { closureId, patch }) {
      const closure = state.closures.find((c) => c.id == closureId);
      if (!closure) return;
      const payload = { ...closure, ...patch };
      commit("MERGE_CLOSURES", [payload]);
    },

    clearState({ state }) {
      console.debug("[closureRead] clearing state");
      state.activeClosure = null;
      state.activeClosureSub = null;
      state.closures = [];
      state.lgaSubscriptions = {};
      state.loading = false;
      state.lastLoad = null;
    }

  },

  getters: {
    activeClosure: state => state.activeClosure,
    closures: state => state.closures,
    loadingClosures: state => state.loading,
    lastLoad: state => state.lastLoad ? moment.unix(state.lastLoad).format('lll') : "None",
  },

}