import Vue from 'vue'
import Vuex from 'vuex'
import app from './modules/app'
import pwa from './modules/pwa'
import auth from './modules/auth'
import media from './modules/media'
import lgaData from './modules/lgaData'
import userData from './modules/userData'
import markerIo from './modules/marker-io'
import customers from './modules/customers'
import connection from './modules/connection'
import navigation from './modules/navigation'
import closureRead from './modules/closureRead'
import closureWrite from './modules/closureWrite'
import notifications from './modules/notifications'
import subscriptions from './modules/subscriptions'
import incidentReports from './modules/incidentReports'
import incidentReportClusters from "./modules/incident-report-clusters"

Vue.use(Vuex)

const modules = {
  app,
  pwa,
  auth,
  media,
  lgaData,
  userData,
  markerIo,
  customers,
  connection,
  navigation,
  closureRead,
  closureWrite,
  notifications,
  subscriptions,
  incidentReports,
  incidentReportClusters,
};

const actions = {
  async clearAppState({ dispatch }) {
    for (const module in modules)
      if ('clearState' in modules[module].actions)
        await dispatch(`${module}/clearState`);
  }
}

const store = new Vuex.Store({ modules, actions })
export default store