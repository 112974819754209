import { doc, increment, setDoc } from "firebase/firestore";
import { db } from "@/firebase/config";

const state = () => ({
  loading: false,
});

const mutations = {
  SET_LOADING(state, payload) { state.loading = payload; },
};

const actions = {
  setLoading(context, isLoading) {
    context.commit('SET_LOADING', isLoading);
  },

  async incrementReportAttempts(context, lga_code) {
    const docRef = doc(db, "app", "reportAttempts");
    const payload = {};
    payload[lga_code] = increment(1);
    await setDoc(docRef, payload, { merge: true });
  }
};

const getters = {
  loading: (state) => state.loading,
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
}