<template>
  <DashboardCard>
    <template #title>Recently Changed</template>
    <b-list-group flush>
      <b-list-group-item
        v-for="(closure, i) in closures"
        :key="i"
        class="d-flex justify-content-between align-items-center"
        :to="`/closures/${closure.id}`"
      >
        <div>
          <b-img
            width="60"
            height="60"
            class="mr-2"
            style="margin-left: -5px"
            :src="`/img/markers/64x64/marker_${closure.type.value}_${closure.condition}.png`"
          />
        </div>
        <div :class="{ 'text-should-wrap': !isMobile }">
          {{ closure.nearestLocation.name }} {{ closure.type.text }}
          <br />
          <small class="text-muted">
            {{ closure.formattedAddress }}
          </small>
        </div>
        <div class="d-flex flex-grow-1"></div>
        <div v-if="!isMobile" class="flex-shrink-0">
          <TimestampTableEntry
            :timestamp="closure.updatedAt.seconds"
            class="text-right"
          />
        </div>
      </b-list-group-item>
      <b-list-group-item v-if="closures?.length === 0">
        No changes made in the past {{ intervalHours }} hours
      </b-list-group-item>
      <b-list-group-item
        v-if="canAdminister"
        to="/closures"
        class="text-muted text-center"
      >
        Administer Closures
      </b-list-group-item>
    </b-list-group>
  </DashboardCard>
</template>

<script>
import DashboardCard from "@/components/DashboardCard";
import TimestampTableEntry from "@/components/table-cells/TimestampTableEntry";

import { db } from "@/firebase/config";
import { manyFromSnapshot } from "@/firebase/firestore-helpers";
import { collection, where, orderBy, limit, onSnapshot, query } from "firebase/firestore";
import moment from "moment";

export default {
  name: "dashboard-card-lga-recent-closure-changes",

  components: {
    DashboardCard,
    TimestampTableEntry,
  },

  mixins: [DashboardCard],

  props: {
    lga: {
      type: Object,
      required: true,
    },
    canAdminister: {
      type: Boolean,
      default: () => false,
    },
  },

  data: () => ({
    closures: [],
    intervalHours: 12,
  }),

  methods: {
    subscribe() {
      this.unsubs.push(
        onSnapshot(
          query(
            collection(db, "closures"),
            where("lga.lga_code", "==", this.lgaCode),
            where("active", "==", true),
            where(
              "updatedAt",
              ">=",
              moment().subtract(this.intervalHours, "hours").toDate()
            ),
            orderBy("updatedAt", "desc"),
            limit(5)
          ),
          (snapshot) => {
            this.closures = manyFromSnapshot(snapshot);
          },
          (e) => {
            this.errorToast(
              `Failed to subscribe to recently changed closures: ${e.message}`
            );
          }
        )
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
