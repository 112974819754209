<template>
  <div class="app">
    <b-overlay id="app-overlay" :class="{ demo: isDemo }" :show="loading" z-index="1001">
      <TopNav />
      <router-view class="under-nav" />
    </b-overlay>
    <b-alert
      :show="!online"
      id="offline-alert"
      variant="light"
      class="d-flex justify-content-between align-items-center"
    >
      <strong> Clozure is {{ online ? "online" : "offline" }}. </strong>
      <em v-if="!online"> Some features may not be available in offline mode. </em>
      <span v-if="!online">
        <small class="text-muted">LAST ONLINE: </small>
        <small>
          {{ moment.unix(lastOnline || 0).format("hh:mmA") }}
        </small>
      </span>
    </b-alert>
  </div>
</template>

<script>
import TopNav from "./components/TopNav";
import { mapState, mapActions, mapGetters } from "vuex";
import { doc, onSnapshot } from "firebase/firestore";
import { auth, db } from "@/firebase/config";
import ConnectionMonitor from "@/mixins/ConnectionMonitor";
import FirebaseCloudMessaging from "@/mixins/FirebaseCloudMessaging";
import AppUpdate from "@/mixins/AppUpdate";

export default {
  name: "clozure-app",

  components: {
    TopNav,
  },

  mixins: [ConnectionMonitor, FirebaseCloudMessaging, AppUpdate],

  metaInfo: {
    title: "Road closure & incident mapping",
    titleTemplate: "%s | Clozure",
  },

  data: () => ({
    unsub: null,
    claims: null,
  }),

  watch: {
    user() {
      this.initialiseUserData();
    },
  },

  computed: {
    ...mapGetters("auth", ["authed", "user"]),
    ...mapGetters("app", ["loading"]),
    ...mapState("connection", ["online", "lastOnline"]),

    isDemo() {
      return process.env.VUE_APP_ENV === "demo";
    },
  },

  methods: {
    ...mapActions("app", ["setLoading"]),

    async initialiseUserData() {
      if (this.user) {
        await this.$store.dispatch("notifications/subscribe", this.user.uid);
        await this.$store.dispatch("subscriptions/loadClosureSubs", this.user.uid);
        this.subscribeToCustomClaims();
        console.debug(`[App] watch:user`, `subscribed to notifications`, {
          user: this.user,
        });
      } else {
        await this.$store.dispatch("notifications/unsubscribe");
        console.debug(`[App] watch:user`, `unsubscribed from notifications`);
      }
    },

    async logout() {
      await this.$store.dispatch("auth/logout");
      this.$router.push("/");
    },

    subscribeToCustomClaims() {
      if (this.unsub) this.unsub();

      const user = auth.currentUser;

      if (!user) return;

      const onSnap = async () => {
        const { claims } = await user.getIdTokenResult(true);
        this.claims = claims;
        if (claims.managed) {
          await this.$store.dispatch("customers/loadCustomer", claims.managed);
        }
      };

      const docRef = doc(db, "users", user.uid);

      this.unsub = onSnapshot(docRef, onSnap, (e) => console.error(e));
    },
  },

  async mounted() {
    await this.$store.dispatch("lgaData/loadCachedImportTimestamp");
    await this.initialiseUserData();

    // sub to push notification from FCM mixin
    this.checkFcmToken();
    this.subscribeToPushNotifications();
  },

  beforeDestroy() {
    if (this.unsub) this.unsub();
  },
};
</script>

<style scoped>
#app-overlay {
  overflow: hidden;
  width: 100%;
  min-height: 100vh;
}

#app-overlay.demo {
  min-height: calc(100vh - 44px);
}

#offline-alert {
  z-index: 5;
  position: fixed;
  bottom: 5px;
  margin: 0 5px;
  width: calc(100% - 10px);
}
</style>

<style>
.fixed-map .popover-body {
  padding: 0.25rem;
}
</style>

<style>
html {
  font-size: 15px;
}

th {
  text-transform: uppercase;
  font-size: 0.9rem;
  font-weight: lighter;
  letter-spacing: 0.05rem;
}

th,
td {
  vertical-align: middle !important;
}
</style>
<style>
dl.horizontal {
  font-size: 0.9rem;
  display: grid;
  grid-template-rows: auto auto;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  grid-column-gap: 20px;
  margin-bottom: 0;
}
dl.horizontal dd {
  margin-bottom: 0;
}
</style>
