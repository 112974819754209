<template>
  <DashboardCard>
    <template #title>
      <span>Pending Access Requests</span>
      <HelpPendingAccessRequests
        triggers="click blur"
        style="transform: translateY(-2.5px)"
      />
    </template>
    <template #header-right>
      <b-badge variant="primary" round v-if="requestCount > 0">
        {{ requestCount }}
      </b-badge>
    </template>
    <b-list-group flush>
      <b-list-group-item
        v-for="(request, i) in requests"
        :key="i"
        href="#"
        :to="`/lga/${lgaCode}/accounts`"
      >
        {{ request?.user?.displayName }} ({{ request?.user?.email }})
      </b-list-group-item>
      <b-list-group-item v-if="requests?.length === 0">
        There are no pending access requests.
      </b-list-group-item>
      <b-list-group-item :to="`/lga/${lgaCode}/accounts`" class="text-center text-muted">
        Go to User Management
      </b-list-group-item>
    </b-list-group>
  </DashboardCard>
</template>

<script>
import DashboardCard from "@/components/DashboardCard";
import HelpPendingAccessRequests from "@/components/HelpPendingAccessRequests";
import DashboardStaffCard from "@/mixins/DashboardStaffCard";
import { db } from "@/firebase/config";
import {
  onSnapshot,
  collection,
  query,
  orderBy,
  limit,
  getCountFromServer,
} from "firebase/firestore";
import { manyFromSnapshot } from "@/firebase/firestore-helpers";

export default {
  name: "dashboard-card-lga-pending-access-requests",

  components: {
    DashboardCard,
    HelpPendingAccessRequests,
  },

  mixins: [DashboardCard, DashboardStaffCard],

  data: () => ({
    requests: [],
    requestCount: 0,
  }),

  methods: {
    async refreshCount() {
      try {
        const colRef = collection(db, "lga_data", this.lgaCode, "access_requests");
        const snapshot = await getCountFromServer(colRef);
        this.requestCount = snapshot.data().count;
      } catch (e) {
        console.error(`Failed to refresh access request count: ${e.message}`);
        this.requestCount = 0;
      }
    },

    subscribe() {
      const colRef = collection(db, "lga_data", this.lgaCode, "access_requests");
      const q = query(colRef, orderBy("requestedAt", "desc"), limit(5));
      const onSnap = (snapshot) => {
        this.requests = manyFromSnapshot(snapshot);
        this.refreshCount();
      };
      const onError = (e) => {
        this.errorToast(`Failed to subscribe to access requests: ${e.message}`);
      };
      this.unsubs.push(onSnapshot(q, onSnap, onError));
    },
  },
};
</script>

<style lang="scss" scoped></style>
