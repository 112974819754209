<script>
export default {
    name: "login-msg-dialog",

    methods: {
        async LoginMsgDialog(action) {
            const response = await this.$bvModal.msgBoxConfirm(
                "You need to be logged in to " + action + ".",
                {
                    title: "Login Required",
                    size: "sm",
                    centered: true,
                    buttonSize: "sm",
                    okTitle: "Sign up",
                    // okVariant: "primary",
                    cancelTitle: "OK",
                    cancelVariant: "light",
                }
            );

            if (response) {
                this.$router.push({ name: "sign-up" });
            }
        }
    }
}
</script>