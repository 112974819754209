<script>
import { messaging, getFCMToken, deleteFCMToken } from "@/firebase/config";
import { mapGetters } from "vuex";
import { onMessage } from "firebase/messaging";

export default {
  name: "firebase-cloud-messaging-mixin",

  data: () => ({
    fcmLoading: false,
    notificationPermissionDenied: null,
  }),

  computed: {
    ...mapGetters("userData", ["fcmEnabled", "fcmToken", "fcmRevoked"]),

    notificationsAvailable() {
      return typeof Notification === "function";
    },
  },

  mounted() {
    this.refreshNotificationPermissionStatus();
  },

  methods: {
    async checkFcmToken() {
      if (this.fcmRevoked) {
        console.debug(
          "[FirebaseCloudMessaging] permissions previously revoked on this device"
        );
        return;
      }

      const permission = Notification?.permission;
      if (permission !== "granted") return;
      try {
        await this.refreshToken();
      } catch (e) {
        console.warn(`Error checking FCM token: ${e.message}`);
      }
    },

    async refreshToken() {
      const token = await getFCMToken();
      console.debug("token", token);
      if (token) {
        await this.$store.dispatch("userData/setFcmToken", token);
      } else {
        throw new Error(
          "No registration token available. Request permission to generate one."
        );
      }
    },

    async subscribeToPushNotifications() {
      // call once from within Vue app
      onMessage(messaging, (e) => {
        console.debug(`Message received`, e);
        // const { title, body } = e.data;
        // this.infoToast(body, { title });
      });
    },

    async onEnableMessaging() {
      try {
        this.fcmLoading = true;
        await this.$store.dispatch("userData/clearFcmRevokation");
        await this.executeEnableMessaging();
      } catch (e) {
        this.errorToast(e.message);
      } finally {
        this.refreshNotificationPermissionStatus();
        this.fcmLoading = false;
      }
    },

    async onDisableMessaging() {
      try {
        this.fcmLoading = true;
        await this.executeDisableMessaging();
      } catch (e) {
        this.errorToast(e.message);
      } finally {
        this.fcmLoading = false;
      }
    },

    refreshNotificationPermissionStatus() {
      this.notificationPermissionDenied = Notification?.permission === "denied";
    },

    async executeEnableMessaging() {
      try {
        const permission = await Notification.requestPermission();
        if (permission === "granted") {
          await this.refreshToken();
        }
      } catch (e) {
        console.error(`Failed to enable FCM: ${e.message}`);
      }
    },

    async executeDisableMessaging() {
      const token = await getFCMToken();
      if (token) {
        await this.$store.dispatch("userData/removeFcmToken", token);
        await deleteFCMToken();
      } else {
        throw new Error("No registration token available");
      }
    },
  },
};
</script>
