<template>
  <b-badge :variant="variant" v-bind="$attrs">
    {{ statusString | titleCase }}
  </b-badge>
</template>

<script>
export default {
  name: "payment-status-badge",

  props: {
    status: {
      type: String,
      default: () => "",
    },
  },

  computed: {
    variant() {
      switch (this.status) {
        case "succeeded":
          return "success";
        case "none":
          return "secondary";
        default:
          return "danger";
      }
    },

    statusString() {
      if (this.status === "succeeded") return "success";
      return this.status;
    },
  },
};
</script>

<style lang="scss" scoped></style>
