<template>
  <b-badge :variant="variant">{{ text }}</b-badge>
</template>

<script>
export default {
  name: "task-status-badge",

  props: {
    status: {
      type: String,
      default: "Created",
    },
  },

  computed: {
    variant() {
      switch (this.status) {
        case "Error":
          return "danger";
        case "Draft":
          return "light";
        case "Generating":
          return "info";
        case "Awaiting Approval":
          return "warning";
        case "Approved":
          return "success";
        default:
          return "default";
      }
    },
    text() {
      return this.status;
    },
  },
};
</script>

<style lang="scss" scoped></style>
