<template>
  <b-overlay class="demo" :show="loading" z-index="1001">
    <div class="toolbar p-2 d-flex justify-content-center align-items-center">
      <b-container fluid>
        <b-button-toolbar class="d-flex align-items-center" style="gap: 7px">
          <b-button-group>
            <b-button
              exact
              exact-active-class="active"
              variant="outline-dark"
              size="sm"
              v-b-modal.demoInfo
            >
              Demo Info
            </b-button>
          </b-button-group>

          <div class="d-flex flex-grow-1"></div>

          <div
            class="d-flex text-right"
            :class="{ 'flex-column': isMobile, 'align-items-center': !isMobile }"
            style="gap: 5px"
          >
            <div>Choose a Persona</div>
            <b-button-group>
              <b-button
                size="sm"
                v-for="persona of personas"
                variant="outline-primary"
                :key="persona.email"
                @click="setActivePersona(persona)"
                :pressed="user?.email === persona.email"
              >
                {{ persona.name }}
              </b-button>
            </b-button-group>
          </div>
        </b-button-toolbar>
      </b-container>
    </div>
    <App class="nested-app" />

    <DemoInfoModal :personas="personas" />
  </b-overlay>
</template>

<script>
import App from "@/App";
import { mapGetters } from "vuex";
import { auth, functions } from "@/firebase/config";
import { httpsCallable } from "firebase/functions";
import { signInWithCustomToken } from "firebase/auth";
const setActivePersona = httpsCallable(functions, "demo-setActivePersona");
import DemoInfoModal from "@/components/DemoInfoModal";

export default {
  name: "clozure-demo",

  components: {
    App,
    DemoInfoModal,
  },

  data: () => ({
    loading: false,
    personas: [
      { name: "General Public", email: "alex.smith@searingpost.com" },
      { name: "Council Staff", email: "council.staff@forbes.nsw.gov.au" },
    ],
  }),

  computed: {
    ...mapGetters("auth", ["user"]),
  },

  mounted() {
    if (!this.user) this.setActivePersona(this.personas[0]);
  },

  methods: {
    async setActivePersona(persona) {
      this.loading = true;
      try {
        this.$nextTick(() => {
          this.$refs.modal?.hide?.();
        });
        await this.$store.dispatch("auth/logout");
        const response = await setActivePersona(persona);

        const { error, message, token } = response.data;
        if (error) throw new Error(message);

        await signInWithCustomToken(auth, token);
        console.debug("signed in with custom token");

        this.$router.push("/dashboard");
      } catch (e) {
        this.errorToast(`Failed to set user profile: ${e.message}`);
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.toolbar {
  background-color: #ddd;
}
>>> .nested-app {
  width: 100%;
  height: calc(100vh - 44px) !important;
  overflow: auto;
}
</style>
