import moment from "moment";

const CreateFilters = (Vue) => {

    Vue.filter('titleCase', function (value) {
        if (value) {
            return (value)
                .split(' ')
                .map(w => w.charAt(0).toUpperCase() + w.slice(1))
                .join(' ');
        }
    });

    Vue.filter('currency', function (value) {
        if (value) {
            return value.toLocaleString('en-AU', { style: 'currency', currency: 'AUD' });
        }
    });

    Vue.filter('moment', function (value, format) {
        if (value) {
            if (format === "fromNow") {
                return moment(value).fromNow();
            } else {
                return moment(value).format(format || 'DD/MM/YYYY');
            }
        }
    });

    Vue.filter('moment1k', function (value, format) {
        if (value) {
            if (format === "fromNow") {
                return moment(value * 1000).fromNow();
            } else {
                return moment(value * 1000).format(format || 'DD/MM/YYYY');
            }
        }
    });

}

export default CreateFilters