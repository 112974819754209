import { collection, onSnapshot, addDoc, query, orderBy, where, doc, updateDoc, getDoc, setDoc } from "firebase/firestore";
import { db } from "@/firebase/config";
import moment from "moment";
import { oneFromSnapshot, manyFromSnapshot } from "@/firebase/firestore-helpers";
import Vue from "vue";

export default {

    namespaced: true,

    state: () => ({
        activeNotification: null,
        notifications: [],
        unsub: null,
    }),

    mutations: {
        SET_NOTIFICATIONS(state, payload) {
            Vue.set(state, "notifications", payload);
        },

        ADD_NOTIFICATION(state, payload) {
            state.notifications.push(payload)
        },

        UPDATE_NOTIFICATION(state, notification) {
            const index = state.notifications.findIndex(n => n.id == notification.id);
            if (index == -1) return;
            state.notifications[index] = notification;
            state.notifications = [...state.notifications];
        },

        SET_ACTIVE_NOTIFICATION(state, notification) {
            Vue.set(state, "activeNotification", notification);
        },
    },

    actions: {
        async subscribe({ commit, state }, userId) {
            if (!userId) throw new Error("Can't subscribe to notifications for a ghost!!");
            const q = query(
                collection(db, "notifications"),
                where("user.uid", "==", userId),
                orderBy("timestamp", "desc")
            );
            const onSnap = (snap) => commit("SET_NOTIFICATIONS", manyFromSnapshot(snap));
            const onError = (e) => console.error(`Failed to sub to notifications: ${e.message}`);
            if (state.unsub) state.unsub();
            state.unsub = onSnapshot(q, onSnap, onError);
            return state.unsub;
        },

        async addNotification({ commit }, notification) {
            const payload = { timeToLive: 604800, ...notification }; // set default TTL if not provided
            const docRef = await addDoc(collection(db, "notifications", payload));
            payload.id = docRef.id;
            commit("ADD_NOTIFICATION", payload);
        },

        async markAsSeen({ commit }, notification) {
            const timestamp = moment().unix()
            const docRef = doc(db, "notifications", notification.id);
            await updateDoc(docRef, { seen: timestamp })
            notification.seen = timestamp;
            commit("UPDATE_NOTIFICATION", notification);
        },

        async markAsRead({ commit }, notification) {
            const timestamp = moment().unix()
            const docRef = doc(db, "notifications", notification.id);
            const payload = { read: timestamp };
            if (!notification.seen) payload.seen = timestamp;
            await updateDoc(docRef, payload)
            notification = { ...notification, ...payload };
            commit("UPDATE_NOTIFICATION", notification);
        },

        unsubscribe({ state, commit }) {
            if (state.unsub) state.unsub()
            commit("SET_NOTIFICATIONS", []);
        },

        async loadSingle({ commit }, id) {
            const snapshot = await getDoc(doc(db, "notifications", id));
            const notification = oneFromSnapshot(snapshot);
            commit("SET_ACTIVE_NOTIFICATION", notification);
        },

        async subSingle({ commit }, id) {
            const docRef = doc(db, "notifications", id);
            const onSnap = (snap) => commit("SET_ACTIVE_NOTIFICATION", oneFromSnapshot(snap));
            const onError = (e) => console.error(`Failed to load notification: ${e.message}`);
            return onSnapshot(docRef, onSnap, onError);
        },

        async markAllAsSeen({ dispatch, getters }) {
            const promises = getters.unseen.map((n) => dispatch("markAsSeen", n));
            await Promise.all(promises);
        },

        async markAllAsRead({ dispatch, getters }) {
            const promises = getters.unread.map((n) => dispatch("markAsRead", n));
            await Promise.all(promises);
        },

        async updateNotification({ commit }, notification) {
            const docRef = doc(db, "notifications", notification.id);
            await setDoc(docRef, notification, { merge: true });
            commit("UPDATE_NOTIFICATION", notification);
        },

        async markReminderAsActioned({ state, dispatch }, { notificationId, closureId }) {
            // find notification object
            let n;
            if (state.activeNotification?.id == notificationId)
                n = state.activeNotification;
            else
                n = state.notifications.find(n => n.id == notificationId);
            if (!n) throw new Error(`Notification with id '${notificationId}' does not exist`);

            // find item within object
            let changed = false;
            const markItemIfMatches = (item) => {
                if (item.id == closureId) {
                    item.actioned = moment().unix();
                    changed = true;
                }
            }

            n.data.scheduled.forEach(markItemIfMatches);
            n.data.overdue.forEach(markItemIfMatches);
            n.data.stale.forEach(markItemIfMatches);

            try {
                if (changed) await dispatch("updateNotification", n);
            } catch (e) {
                throw new Error(`Failed to update the notification: ${e.message}`);
            }
        },

        clearState({ state }) {
            console.debug("[notifications] clearing state");
            state.activeNotification = null;
            state.notifications = [];
            if (state.unsub) state.unsub();
            state.unsub = null;
        },
    },

    getters: {
        notifications: (state) => state.notifications.sort((a, b) => b.timestamp - a.timestamp),
        unseen: (state, getters) => getters.notifications.filter((n) => n.seen == null),
        unread: (state, getters) => getters.notifications.filter((n) => n.read == null),
        activeNotification: (state) => state.activeNotification,
    },

}