<template>
  <b-form-input type="datetime-local" v-model="timestamp" v-bind="$attrs"></b-form-input>
</template>

<script>
import moment from "moment";
export default {
  name: "datetime-local-input",
  props: ["value"],
  computed: {
    timestamp: {
      get() {
        return moment.unix(this.value).format("YYYY-MM-DD HH:mm");
      },
      set(v) {
        this.$emit("input", moment(v).unix());
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
