<template>
  <div class="notification-list">
    <div class="list-header" v-if="!embeddedInDropdown">
      <h3 v-if="showViewAll" class="mb-0">Notifications</h3>
      <h2 v-else class="mb-0">Notifications</h2>

      <b-dropdown no-caret variant="light" right>
        <template #button-content>
          <fa-icon icon="ellipsis-vertical"></fa-icon>
        </template>
        <b-dropdown-item href="#" @click.prevent="onMarkAllAsRead">
          Mark all as read
        </b-dropdown-item>
        <b-dropdown-item to="/profile/notifications" @click="$emit('redirection')">
          Notification settings
        </b-dropdown-item>
        <b-dropdown-item
          to="/notifications"
          @click="$emit('redirection')"
          v-if="showViewAll"
        >
          View all notifications
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <hr v-if="!embeddedInDropdown" />
    <b-button-toolbar class="list-filters">
      <b-button-group>
        <b-button
          variant="light"
          @click="onFilterClick('all')"
          :pressed="active == 'all'"
        >
          All
        </b-button>
        <b-button
          variant="light"
          @click="onFilterClick('unread')"
          :pressed="active == 'unread'"
        >
          Unread
        </b-button>
      </b-button-group>
      <b-button-group>
        <b-button
          variant="light"
          to="/notifications"
          @click="$emit('redirection')"
          v-if="showViewAll"
        >
          View All
        </b-button>
      </b-button-group>
    </b-button-toolbar>

    <h4 v-if="recent.length">Recent</h4>
    <b-list-group v-if="recent.length">
      <NotificationListItem
        v-for="(n, i) of recent"
        :key="i"
        :notification="n"
        @redirection="$emit('redirection')"
      />
      <b-list-group-item
        v-if="showViewAll && recent.length >= 10"
        to="/notifications"
        class="text-center"
      >
        View all notifications
      </b-list-group-item>
    </b-list-group>
    <h4 v-if="recent.length && earlier.length" class="mt-3">Earlier</h4>
    <b-list-group>
      <NotificationListItem
        v-for="(n, i) of earlier"
        :key="i"
        :notification="n"
        @redirection="$emit('redirection')"
      />
      <b-list-group-item
        v-if="showViewAll && recent.length < 10"
        to="/notifications"
        class="text-center"
      >
        View all notifications
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import moment from "moment";
import NotificationListItem from "@/components/notifications/NotificationListItem";
import { mapGetters } from "vuex";

export default {
  name: "notification-list",

  components: { NotificationListItem },

  props: {
    embeddedInDropdown: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    active: "all",
    // lastFetched: 0,
    allFetched: 0,
    count: 10,
    busy: false,
    key: 0,
  }),

  computed: {
    ...mapGetters("notifications", ["notifications", "unseen", "unread"]),

    showViewAll() {
      const re = /^\/notifications\/?$/gm;
      return !re.test(this.$route.path);
    },
    all() {
      return this.notifications;
    },
    filtered() {
      const result = this.active == "all" ? this.all : this.unread;
      if (this.embeddedInDropdown) return result.slice(0, 10);
      return result;
    },
    recent() {
      return this.filtered.filter((n) => n.timestamp >= moment().startOf("day").unix());
    },
    earlier() {
      return this.filtered.filter((n) => n.timestamp < moment().startOf("day").unix());
    },
  },

  methods: {
    onFilterClick(active) {
      if (this.active == active) return;
      this.active = active;
      this.$nextTick(() => {
        this.$emit("fetch-more");
      });
    },

    async onMarkAllAsRead() {
      await this.$store.dispatch("notifications/markAllAsRead");
    },
  },
};
</script>

<style scoped>
.list-header,
.list-filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}
.ellipsis-button {
  border-radius: 40%;
}
</style>
