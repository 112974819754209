<script>
import { collection, limit, onSnapshot, query, where } from "firebase/firestore";
import { mapGetters } from "vuex";
import { db } from "@/firebase/config";

export default {
  name: "staff-onboarding-checkout-sessions",

  data: () => ({
    onboardingCheckoutSession: null,
    onboardingUnsub: null,
  }),

  computed: {
    ...mapGetters("auth", ["user", "staff"]),

    hasOnboardingCheckoutSession() {
      return !!this.onboardingCheckoutSession?.session?.url;
    },
  },

  watch: {
    staff(staff) {
      return staff ? this.subscribeCheckoutSessions() : this.unsubCheckoutSessions();
    },
  },

  async mounted() {
    console.debug(`[StaffOnboardingCheckoutSessions] mounted`);
    if (this.staff) this.subscribeCheckoutSessions();
  },

  beforeDestroy() {
    this.unsubCheckoutSessions();
  },

  methods: {
    subscribeCheckoutSessions() {
      this.unsubCheckoutSessions();

      console.debug(`[StaffOnboardingCheckoutSessions] subscribe`, this.user.email);

      const q = query(
        collection(db, "onboarding_checkout_sessions"),
        where("email", "==", this.user?.email),
        limit(1)
      );

      const onSnap = (snapshot) => {
        this.onboardingCheckoutSession =
          snapshot.size === 0 ? null : snapshot.docs[0].data();
      };

      const onError = (e) =>
        console.error(`Failed to subscribe to checkout session data: ${e.message}`);

      this.onboardingUnsub = onSnapshot(q, onSnap, onError);
    },

    unsubCheckoutSessions() {
      this.onboardingCheckoutSession = null;

      if (this.onboardingUnsub) {
        this.onboardingUnsub();
        this.onboardingUnsub = null;
        console.debug(`[StaffOnboardingCheckoutSessions] unsubscribe`);
      }
    },
  },
};
</script>
