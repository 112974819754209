<template>
  <b-modal
    id="request-access-modal"
    v-bind="$attrs"
    :title="title"
    @ok="onOk"
    @cancel="$emit('cancel', $event)"
    @dismiss="$emit('dismiss', $event)"
  >
    <p>You can request access as a staff member of the {{ lga.lga_name }} LGA.</p>
    <p>
      Please choose your desired roles. An account manager will review your application.
      If you're not sure what to pick you may leave this blank.
    </p>
    <p><strong>Note:</strong> Your account managers may override this choice</p>
    <b-form>
      <b-form-group label="Requested Roles" class="mb-0">
        <SelectAllCheckboxStack
          v-model="roles"
          :options="staffRoles"
          field="value"
          label="text"
          switches
        >
          <template v-slot:label="{ item }">
            <div class="d-flex flex-column justify-space-between pb-2">
              <span>{{ item.text }}</span>
              <small>{{ item.description }}</small>
            </div>
          </template>
        </SelectAllCheckboxStack>
      </b-form-group>
    </b-form>
  </b-modal>
</template>

<script>
import SelectAllCheckboxStack from "@/components/SelectAllCheckboxStack";
import StaffRoles from "@/mixins/StaffRoles";
import { auth } from "@/firebase/config";
import moment from "moment";

export default {
  name: "request-access-modal",

  mixins: [StaffRoles],

  components: { SelectAllCheckboxStack },

  props: {
    lga: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    roles: [],
  }),

  computed: {
    title() {
      return `Request Access to ${this.lga.lga_name} LGA`;
    },
  },

  methods: {
    async onOk() {
      const { uid, email, displayName } = auth.currentUser;
      const { lga_name, lga_code } = this.lga;
      const accessRequest = {
        lga: { lga_name, lga_code },
        roles: this.roles,
        user: { uid, email, displayName },
        requestedAt: moment().unix(),
      };
      await this.$store.dispatch("lgaData/saveManagementRequest", accessRequest);
      await this.$store.dispatch("userData/loadUserAccessRequests");
      this.infoToast(
        `Requested access with ${this.roles.length} ${
          this.roles.length == 1 ? "role" : "roles"
        } to ${this.lga.lga_name} LGA`
      );
    },
  },
};
</script>

<style scoped></style>
