<template>
  <small v-if="timestamp">
    <div>{{ moment.unix(seconds).format(format) }}</div>
    <div>{{ moment.unix(seconds).fromNow() }}</div>
  </small>
</template>

<script>
export default {
  name: "timestamp-table-entry",

  props: {
    timestamp: {
      type: [Number, Object, undefined],
      default: undefined,
    },
    format: {
      type: String,
      default: "MMM D, h:mm A",
    },
  },

  computed: {
    seconds() {
      return this.timestamp?.seconds || this.timestamp || 0;
    },
  },
};
</script>

<style lang="scss" scoped></style>
