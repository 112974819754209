<template>
  <DashboardCard v-show="notificationsAvailable">
    <template #title> Push Notifications </template>
    <b-card-body>
      <UserNotificationOptionsEditForm />
    </b-card-body>
  </DashboardCard>
</template>

<script>
import DashboardCard from "@/components/DashboardCard";
import FirebaseCloudMessaging from "@/mixins/FirebaseCloudMessaging";
import UserNotificationOptionsEditForm from "@/components/UserNotificationOptionsEditForm";

export default {
  name: "dashboard-card-push-notifications",

  mixins: [DashboardCard, FirebaseCloudMessaging],

  components: {
    DashboardCard,
    UserNotificationOptionsEditForm,
  },
};
</script>

<style lang="scss" scoped></style>
