<template>
  <b-card class="dashboard-card" no-body style="align-self: flex-start">
    <b-card-header class="d-flex justify-content-between align-items-center">
      <h5 class="mb-0">
        <slot name="title"></slot>
      </h5>
      <div>
        <slot name="header-right"></slot>
      </div>
    </b-card-header>
    <slot></slot>
    <b-card-footer v-if="hasFooter">
      <slot name="footer"></slot>
    </b-card-footer>
  </b-card>
</template>

<script>
import { auth } from "@/firebase/config";

export default {
  name: "dashboard-card",

  data: () => ({
    hasFooter: false,
    unsubs: [],
  }),

  computed: {
    lgaCode() {
      return this.lga?.lga_code;
    },

    user() {
      return auth.currentUser;
    },
  },

  watch: {
    lgaCode() {
      if (this.lgaCode) this.subscribe();
    },

    user(user) {
      if (!user) {
        for (const unsub of this.unsubs) unsub();
      }
    },
  },

  mounted() {
    if (this.lgaCode) this.subscribe();
  },

  beforeDestroy() {
    for (const unsub of this.unsubs) unsub();
  },

  methods: {
    subscribe() {
      console.info(
        "please override the subscribe method in the dashboard card extension"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.badge {
  font-size: 0.8rem;
}
/deep/ .text-should-wrap {
  flex-basis: 60%;
  flex-grow: 0;
}
</style>
