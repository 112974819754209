<template>
  <DashboardCard>
    <template #title>Pending Incident Reports</template>
    <template #header-right>
      <b-badge v-if="clusterCount > 0" variant="primary">{{ clusterCount }}</b-badge>
    </template>
    <b-list-group flush>
      <b-list-group-item
        v-for="(cluster, i) in clusters"
        :key="i"
        :to="`/report-clusters/${cluster.id}`"
      >
        <div class="d-flex justify-content-between align-items-center">
          <b-img
            width="60"
            height="60"
            :src="`/img/markers/64x64/marker_${cluster.type.value}_undefined.png`"
            class="mr-2"
            style="margin-left: -5px"
          />

          <div :class="{ 'text-should-wrap': !isMobile }">
            <div>{{ cluster.type.text }} with {{ submissionText(cluster) }}</div>
            <small>{{ cluster.formattedAddress }}</small>
          </div>

          <div class="flex-grow-1"></div>

          <div v-if="!isMobile" class="flex-shrink-0">
            <TimestampTableEntry :timestamp="cluster?.updatedAt" class="text-right" />
          </div>
        </div>
      </b-list-group-item>
      <b-list-group-item v-if="clusters?.length === 0">
        There are no pending incident reports.
      </b-list-group-item>
      <b-list-group-item to="/report-clusters" class="text-muted text-center">
        Moderate Incident Reports
      </b-list-group-item>
    </b-list-group>
  </DashboardCard>
</template>

<script>
import DashboardCard from "@/components/DashboardCard";
import DashboardStaffCard from "@/mixins/DashboardStaffCard";
import TimestampTableEntry from "@/components/table-cells/TimestampTableEntry";
import { db } from "@/firebase/config";
import { manyFromSnapshot } from "@/firebase/firestore-helpers";
import {
  collection,
  where,
  orderBy,
  limit,
  onSnapshot,
  query,
  getCountFromServer,
} from "firebase/firestore";

export default {
  name: "dashboard-card-lga-pending-incident-reports",

  components: {
    DashboardCard,
    TimestampTableEntry,
  },

  mixins: [DashboardCard, DashboardStaffCard],

  data: () => ({
    clusterCount: 0,
    clusters: [],
  }),

  methods: {
    async refreshCount() {
      const q = query(
        collection(db, "incident_report_clusters"),
        where("lga.lga_code", "==", this.lgaCode),
        where("status", "==", "pending")
      );
      try {
        const snapshot = await getCountFromServer(q);
        this.clusterCount = snapshot.data().count;
      } catch (e) {
        console.error(`Failed to refresh incident report count: ${e.message}`);
        this.clusterCount = 0;
      }
    },

    subscribe() {
      this.unsubs.push(
        onSnapshot(
          query(
            collection(db, "incident_report_clusters"),
            where("lga.lga_code", "==", this.lgaCode),
            where("status", "==", "pending"),
            orderBy("updatedAt", "desc"),
            limit(5)
          ),
          (snapshot) => {
            this.clusters = manyFromSnapshot(snapshot);
            this.refreshCount();
          },
          (e) => {
            this.errorToast(`Failed to sub to pending incident reports: ${e.message}`);
          }
        )
      );
    },

    submissionText(cluster) {
      const count = Object.keys(cluster?.submissions || {}).length;
      const plural = count === 1 ? "submission" : "submissions";
      return `${count} ${plural}`;
    },
  },
};
</script>

<style lang="scss" scoped></style>
