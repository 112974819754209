import Vue from 'vue'
import App from './App.vue'
import Demo from './Demo.vue'
import router from '@/router'
import store from '@/store'
import { auth, db } from '@/firebase/config'
import { doc, onSnapshot } from 'firebase/firestore'

Vue.config.productionTip = false

// leaflet missing icon fix
import { Icon } from 'leaflet';
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

// vue-meta plugin for page titles
import VueMeta from 'vue-meta'
Vue.use(VueMeta)

// register vue-infinite-scroll
import infiniteScroll from "vue-infinite-scroll";
Vue.use(infiniteScroll);

// register global mixins
import MobileView from "@/mixins/global/MobileView";
Vue.mixin(MobileView);
import Toasts from "@/mixins/global/Toasts";
Vue.mixin(Toasts);
import LoginMsgDialog from "@/mixins/global/LoginMsgDialog";
Vue.mixin(LoginMsgDialog);

// inject moment into Vue
import moment from 'moment'
Vue.prototype.moment = moment

// import individual bootstrap-vue components
import LoadBootstrapVue from './bootstrap-vue';
LoadBootstrapVue(Vue);

// register vue-multiselect
import Multiselect from 'vue-multiselect'
import "vue-multiselect/dist/vue-multiselect.min.css";
Vue.component('vue-multiselect', Multiselect);

// configure font-awesome
import LoadFontAwesome from './font-awesome'
LoadFontAwesome(Vue)

// google maps API & gmap-vue lib
import GmapVue from 'gmap-vue'
Vue.use(GmapVue, {
  load: {
    key: process.env.VUE_APP_MAPS_API_KEY,
    libraries: 'places',
  },
})

// load filters
import CreateFilters from './filters'
CreateFilters(Vue)

// load help components
import HelpMoreInfo from "@/components/HelpMoreInfo";
import HelpMoreInfoMap from "@/components/HelpMoreInfoMap";
Vue.component('help-more-info', HelpMoreInfo);
Vue.component('help-more-info-map', HelpMoreInfoMap);

// import stripe-js
import '@stripe/stripe-js';

// PWA implementation
import './registerServiceWorker'

// our global app css
import './assets/styles/app.scss'

// Wait for firebase auth object to be ready
// before creating the Vue app
let app = '';
let unsub = null;

// TODO: reinstate marker-io
// store.dispatch("markerIo/initialise");

store
  .dispatch("auth/checkAuthRedirect")
  .then((additionalUserInfo) => {
    if (additionalUserInfo?.isNewUser) {
      router.push("/welcome");
    }
  });

/**
 * Refreshes the user's custom claims and updates the vuex store
 * 
 * @param {firebase.User} user the firestore user from onAuthStateChanged
 * @param {Vuex.Store} store the global vuex store object
 * @returns {Promise<function>} the unsubscribe function
 * @see https://firebase.google.com/docs/auth/admin/custom-claims
 * @see https://firebase.google.com/docs/auth/web/manage-users#get_a_users_id_token
 */
async function refreshClaimsOnUserChange(user, store) {
  const onSnap = async () => {
    // load custom claims and set state in vuex store
    const { claims } = await user.getIdTokenResult(true);
    const { admin, staff } = claims;
    store.commit("auth/SET_ADMIN", Boolean(admin));
    store.commit("auth/SET_STAFF", Boolean(staff));

    // load assigned LGAs if staff or admin
    if (staff || admin) {
      await store.dispatch("userData/loadAssignedLGAs", user.email);
      await store.dispatch("userData/loadUserAccessRequests");
    }

    console.debug("[main] auth claims", claims);
  }

  const onError = (e) => {
    console.error("[main] auth claims fetch error:", e.message);
  }

  const docRef = doc(db, "users", user.uid);
  return onSnapshot(docRef, onSnap, onError);
}

auth.onAuthStateChanged(async (user) => {
  console.debug("[main] onAuthStateChanged", { user });

  // remove previous listener
  if (unsub) unsub();

  if (user) {
    // user signed in
    unsub = await refreshClaimsOnUserChange(user, store);
    await store.dispatch("auth/setUser", user);
    if (router.currentRoute.name === 'login') {
      router.push("/");
    }
  } else {
    // user signed out
    await store.dispatch("auth/logout");
    if (router.currentRoute.meta.requiresAuth) {
      router.push("/login");
    }
  }

  /**
   * TODO: re-enable this once marker-io has a clearReporter method
   * 
  // set reporter info in marker-io
  if (process.env.VUE_APP_ENV === 'production') {
    await store.dispatch("markerIo/setReporter", user);
  }
  */

  // initialise the app
  if (!app) {
    app = new Vue({
      name: 'ClozureVueApp',
      router,
      store,
      render: h => h(process.env.VUE_APP_ENV === 'demo' ? Demo : App)
    }).$mount('#app');
  }
});
