<script>
import { mapGetters } from "vuex";

export default {
  name: "pwa-install-mixin",

  computed: mapGetters("pwa", [
    "pwaInstalled",
    "pwaInstallAvailable",
    "pwaDeferredPromptAvailable",
  ]),

  beforeMount() {
    this.$store.dispatch("pwa/initialise", window);
  },

  methods: {
    async installPWA() {
      try {
        await this.$store.dispatch("pwa/installPWA");
      } catch (e) {
        this.errorToast(
          `Failed to gather permission to install Clozure to your device: ${e.message}`
        );
      }
    },
  },
};
</script>
