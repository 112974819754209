<template>
  <b-badge :variant="variant" v-bind="$attrs">
    {{ status | titleCase }}
  </b-badge>
</template>

<script>
export default {
  name: "subscription-status-badge",

  props: {
    status: {
      type: String,
      default: () => "",
    },
  },

  computed: {
    variant() {
      switch (this.status) {
        case "active":
          return "success";
        case "trialing":
          return "warning";
        case "none":
          return "secondary";
        default:
          return "danger";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
