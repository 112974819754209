<template>
  <b-nav-item-dropdown
    ref="dropdown"
    id="notifications-dropdown"
    variant="light"
    right
    boundary="window"
    @shown="onDropdownShown"
    @hidden="onDropdownHidden"
  >
    <template #button-content>
      <span v-show="isMobile" class="mr-2">Notifications</span>
      <fa-icon icon="bell" v-if="!isMobile" />
      <b-badge
        class="unread-badge"
        :style="{ opacity: unseen.length ? '1' : '0' }"
        variant="info"
        >{{ unseen.length }}</b-badge
      >
    </template>
    <b-dropdown-text class="notifications-dropdown-item">
      <NotificationList embedded-in-dropdown @redirection="$refs.dropdown.hide()" />
    </b-dropdown-text>
  </b-nav-item-dropdown>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import NotificationList from "@/components/notifications/NotificationList";

const MARK_AS_SEEN_DELAY = 1000;

export default {
  name: "notfications-dropdown",

  components: { NotificationList },

  data: () => ({
    initialised: false,
    markAsSeenTimeout: null,
  }),

  computed: {
    ...mapGetters("notifications", ["notifications", "unread", "unseen"]),
  },

  watch: {
    notifications(notifications, oldNotifications) {
      if (!this.initialised) {
        // skip the first notification for initial load
        this.initialised = true;
        console.debug(
          `[NotificationsDropdown] watch:notifications`,
          `first change so no need to notify`
        );
        return;
      }

      const newNotifications = notifications.filter((n) => {
        return !n.seen && !oldNotifications.find((old) => old.id === n.id);
      });

      // if there are more than 3 new notifications, just show a toast
      if (newNotifications.length > 3) {
        const h = this.$createElement;
        const vNodesMsg = h("span", { class: { "text-muted": true } }, [
          `You have ${newNotifications.length} new notifications`,
        ]);
        this.infoToast([vNodesMsg], {
          title: "Notifications",
          autoHideDelay: 7000,
        });
        return;
      }

      newNotifications.forEach((notification) => {
        const h = this.$createElement;
        const linkAttrs = {
          props: { to: notification.redirectTo || `/notifications/${notification.id}` },
          nativeOn: { click: () => this.markAsRead(notification) },
        };
        const vNodesMsg = h("router-link", linkAttrs, [notification.body]);
        const vNodesTimestamp = h("small", { class: { "text-muted": true } }, [
          `${moment.unix(notification.timestamp).format("lll")}`,
        ]);
        this.infoToast([vNodesMsg, h("br"), vNodesTimestamp], {
          title: notification.title,
        });
      });

      console.debug(`[NotificationsDropdown] watch:notifications`, { newNotifications });
    },
  },

  methods: {
    ...mapActions("notifications", ["markAsRead"]),

    gotoNotificationResource(notification) {
      this.markAsRead(notification);
      if (!notification.redirectTo) {
        if (this.$route.path != `/notifications/${notification.id}`)
          this.$router.push(`/notifications/${notification.id}`);
      } else {
        if (this.$route.query["id"] != notification.resourceId) {
          this.$router.push(notification.redirectTo);
        }
      }
    },

    onDropdownShown() {
      this.markAsSeenTimeout = setTimeout(() => {
        this.$store.dispatch("notifications/markAllAsSeen");
        this.markAsSeenTimeout = null;
      }, MARK_AS_SEEN_DELAY);
    },

    onDropdownHidden() {
      if (this.markAsSeenTimeout) {
        clearTimeout(this.markAsSeenTimeout);
      }
    },
  },
};
</script>

<style scoped>
#notifications-dropdown {
  z-index: 1001;
}

::v-deep .dropdown-toggle::after {
  opacity: 0;
}

.unread-badge {
  transform: translate(-3px, -10px);
  border-radius: 100%;
}

.notifications-dropdown-item {
  min-width: 400px;
  max-height: 85vh;
  overflow: auto;
}

@media screen and (max-width: 768px) {
  .notifications-dropdown-item {
    min-width: 0;
  }
}
</style>
