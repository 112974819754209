import { arrayRemove, arrayUnion, collection, collectionGroup, doc, getDoc, getDocs, onSnapshot, query, serverTimestamp, setDoc, updateDoc, where } from "firebase/firestore";
import { db, auth } from "@/firebase/config";
import { manyFromSnapshot } from "@/firebase/firestore-helpers";

export default {

    namespaced: true,

    state: () => ({
        userData: null,
        profile: {},
        mapOptions: {},
        assignedLGAs: [],
        initialised: false,
        accessRequests: [],
        fcmToken: null,
        fcmRevoked: localStorage.getItem("fcmRevoked"),
    }),

    mutations: {
        SET_USER_DATA(state, payload) {
            state.userData = payload;
        },
        SET_PROFILE(state, payload) {
            state.profile = payload;
        },

        SET_MAP_OPTIONS(state, payload) {
            state.mapOptions = payload;
        },

        SET_ADDRESS_AND_LGA(state, { address, lga }) {
            state.profile.address = address;
            state.profile.lga = lga;
        },

        SET_ASSIGNED_LGAS(state, lgas) {
            state.assignedLGAs = lgas
        },

        ADD_ASSIGNED_LGA(state, lga) {
            if (!state.assignedLGAs?.find(assigned => assigned.lga_code == lga.lga_code))
                state.assignedLGAs.push(lga)
        },

        REMOVE_ASSIGNED_LGA(state, lga) {
            state.assignedLGAs.splice(state.assignedLGAs?.indexOf(lga), 1)
        },

        SET_INITIALISED(state) {
            state.initialised = true;
        },

        SET_FCM_TOKEN(state, token) {
            state.fcmToken = token;
        },

        SET_ACCESS_REQUESTS(state, payload) {
            state.accessRequests = payload;
        },

        SET_FCM_REVOKED(state, payload) {
            state.fcmRevoked = payload;
        }
    },

    actions: {

        async loadUserAccessRequests({ commit }) {
            const user = auth.currentUser;
            const q = query(collectionGroup(db, "access_requests"), where("user.email", "==", user.email));
            const snapshot = await getDocs(q);
            const accessRequests = manyFromSnapshot(snapshot);
            commit("SET_ACCESS_REQUESTS", accessRequests);
            return accessRequests;
        },

        async loadAssignedLGAs(context, email) {
            // get all instances of manager assignments with this email address
            const q = query(collectionGroup(db, "managers"), where("email", "==", email));
            const onSnap = async (snapshot) => {
                const lgas = [];
                // map LGA data alongside lga_code and management roles
                for (const doc of snapshot.docs) {
                    const lgaRef = doc.ref.parent.parent;
                    const lgaSnap = await getDoc(lgaRef);
                    if (lgaSnap.exists()) {
                        const lga = {
                            id: lgaSnap.id,
                            roles: doc.data().roles || [],
                            ...lgaSnap.data().properties,
                        };
                        lgas.push(lga);
                    }
                }
                context.commit("SET_ASSIGNED_LGAS", lgas);
            }
            const onError = (e) => {
                if (e.code == "permission-denied") {
                    return;
                }
                console.error(`Failed to load LGA assignments for ${email}: ${e.message}`);
            }
            return onSnapshot(q, onSnap, onError);
        },

        async loadUserData({ commit }, userId) {
            const onSnap = async (snapshot) => {
                if (snapshot.exists()) {
                    const userData = snapshot.data();
                    commit("SET_USER_DATA", userData);
                    const { profile, mapOptions } = userData;
                    commit("SET_PROFILE", profile);
                    commit("SET_MAP_OPTIONS", mapOptions);
                }
                commit("SET_INITIALISED");
            }

            // perform an initial load to make sure there's no race condition with onSnapshot
            const docRef = doc(db, "users", userId);
            const snapshot = await getDoc(docRef);
            onSnap(snapshot);

            // now return the subscription to snapshots
            return onSnapshot(docRef, onSnap, (e) => console.error(`Failed to load user data: ${e.message}`));
        },

        async saveAddressAndLga(context, { address, lga }) {
            if (!auth.currentUser?.uid) throw new Error("You must be signed in");
            const docRef = doc(db, "users", auth.currentUser.uid);
            const snapshot = await getDoc(docRef);
            if (snapshot.exists()) {
                await updateDoc(docRef, { "profile.address": address, "profile.lga": lga }, { merge: true });
            } else {
                await setDoc(docRef, { profile: { address, lga } }, { merge: true });
            }
            context.commit("SET_ADDRESS_AND_LGA", { address, lga });
        },

        async saveProfile({ commit }, { userId, profile }) {
            const docRef = doc(db, "users", userId);
            await setDoc(docRef, { profile }, { merge: true });
            commit("SET_PROFILE", profile)
            return profile;
        },

        async saveMapOptions({ commit }, { userId, mapOptions }) {
            const docRef = doc(db, "users", userId);
            await setDoc(docRef, { mapOptions }, { merge: true })
            commit("SET_MAP_OPTIONS", mapOptions);
            return mapOptions;
        },

        async logTermsAcceptance() {
            const userId = auth.currentUser.uid;
            const docRef = doc(db, "users", userId);
            await updateDoc(docRef, { acceptedTermsAt: serverTimestamp() });
        },

        async setFcmToken(context, token) {
            const userId = auth.currentUser.uid;
            const docRef = doc(db, "users", userId);
            if (token) {
                context.commit("SET_FCM_TOKEN", token);
                await setDoc(docRef, { fcmTokens: arrayUnion(token) }, { merge: true });
            }
        },

        async removeFcmToken(context, token) {
            const userId = auth.currentUser.uid;
            const docRef = doc(db, "users", userId);
            await setDoc(docRef, { fcmTokens: arrayRemove(token) }, { merge: true });
            context.commit("SET_FCM_TOKEN", null);

            // store the revokation on this device so we don't fetch the token on reload
            localStorage.setItem("fcmRevoked", true);
            context.commit("SET_FCM_REVOKED", true);
        },

        async clearFcmRevokation(context) {
            localStorage.removeItem("fcmRevoked");
            context.commit("SET_FCM_REVOKED", false);
        },

        async hasRole(context, role) {
            const groupRef = collectionGroup(db, "managers");
            const user = context.rootGetters["auth/user"];
            if (!user) return false;
            const q = query(groupRef, where("email", "==", user.email), where("roles", "array-contains", role));
            const snapshot = await getDocs(q);
            return !snapshot.empty;
        },

        async isStaffOfLGA(context, lgaCode) {
            const email = context.rootGetters["auth/user"]?.email;
            if (!email) return false;
            const colRef = collection(db, "lga_data", lgaCode, "managers");
            const snapshot = await getDocs(colRef);
            const emails = snapshot.docs.map((docRef) => docRef.id);
            return emails.includes(email);
        },

        clearState({ state }) {
            console.debug("[userData] clearing state");
            state.profile = {};
            state.mapOptions = {};
            state.assignedLGAs = [];
            state.initialised = false;
        },

    },

    getters: {
        userData: (state) => state.userData,
        profile: (state) => state.profile,
        mapOptions: (state) => state.mapOptions,

        assignedLGAs: (state) => state.assignedLGAs,

        managedLGAs: (state) => state.assignedLGAs.filter((lga) => lga.roles.includes("account_manager")),
        moderatedLGAs: (state) => state.assignedLGAs.filter((lga) => lga.roles.includes("submission_moderator")),
        administeredLGAs: (state) => state.assignedLGAs.filter((lga) => lga.roles.includes("closure_administrator")),
        exportableLGAs: (state) => state.assignedLGAs.filter((lga) => lga.roles.includes("media_comms_manager")),

        managedCodes: (state, getters) => getters.managedLGAs.map((lga) => lga.lga_code),
        moderatedCodes: (state, getters) => getters.moderatedLGAs.map((lga) => lga.lga_code),
        administeredCodes: (state, getters) => getters.administeredLGAs.map((lga) => lga.lga_code),
        exportableCodes: (state, getters) => getters.exportableLGAs.map((lga) => lga.lga_code),

        initialised: (state) => state.initialised,
        accessRequests: (state) => state.accessRequests,
        fcmToken: (state) => state.fcmToken,
        fcmEnabled: (state) => Boolean(state.fcmToken),
        fcmRevoked: (state) => state.fcmRevoked === 'true',
    },

}