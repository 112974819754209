export async function reverseGeocode(latLng) {
    // create URL
    const urlBase = `https://maps.googleapis.com/maps/api/geocode/json`;
    const apiKey = process.env.VUE_APP_MAPS_API_KEY;
    const latLngString = `${latLng.lat},${latLng.lng}`
    const url = `${urlBase}?key=${apiKey}&latlng=${latLngString}`;

    console.debug(`[geocode] url`, url);

    // fetch JSON response from
    const response = await fetch(url);
    const json = await response.json();
    return json;
}