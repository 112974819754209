<template>
  <DashboardCard>
    <template #title>Billing Information</template>
    <b-list-group flush>
      <b-list-group-item class="p-0">
        <div class="item">
          <div class="label">Subscription</div>
          <SubscriptionStatusBadge :status="customer?.subscriptionStatus" />
        </div>
      </b-list-group-item>
      <b-list-group-item class="item">
        <div class="label">Term</div>
        <b-badge variant="info">
          {{ subscriptions?.[0]?.plan?.interval }}
        </b-badge>
      </b-list-group-item>
      <b-list-group-item class="item" v-if="!isTrialing">
        <div class="label">Last Payment</div>
        <PaymentStatusBadge :status="customer?.paymentStatus" />
      </b-list-group-item>
      <b-list-group-item class="item" v-if="!isTrialing">
        <div class="label">Next Payment</div>
        <b-badge variant="info">{{ nextPaymentDate }}</b-badge>
        <!-- <b-badge variant="info"> 30th June 2023 </b-badge> -->
      </b-list-group-item>
      <b-list-group-item class="item" v-if="isTrialing">
        <div class="label">Trial Ends</div>
        <b-badge variant="info">
          {{ moment.unix(subscriptions?.[0]?.trial_end).format("LL") }}
        </b-badge>
      </b-list-group-item>
      <b-list-group-item
        class="text-center text-muted"
        :to="`/lga/${this.lga?.lga_code}/billing-info`"
      >
        Go to Account Management
      </b-list-group-item>
    </b-list-group>
  </DashboardCard>
</template>

<script>
import DashboardCard from "@/components/DashboardCard";
import DashboardStaffCard from "@/mixins/DashboardStaffCard";
import SubscriptionStatusBadge from "@/components/SubscriptionStatusBadge";
import PaymentStatusBadge from "@/components/PaymentStatusBadge";
import { oneFromSnapshot, manyFromSnapshot } from "@/firebase/firestore-helpers";
import { db } from "@/firebase/config";
import { onSnapshot, collection, doc } from "firebase/firestore";
import moment from "moment";

export default {
  name: "dashboard-card-lga-billing-info",

  components: {
    DashboardCard,
    SubscriptionStatusBadge,
    PaymentStatusBadge,
  },

  mixins: [DashboardCard, DashboardStaffCard],

  data: () => ({
    customer: null,
    subscriptions: [],
    invoices: [],
  }),

  computed: {
    nextPaymentDate() {
      const nextPayment = this.subscriptions?.[0]?.current_period_end;
      return nextPayment ? moment.unix(nextPayment).format("LL") : "";
    },

    isTrialing() {
      return this.customer?.subscriptionStatus === "trialing";
    },
  },

  methods: {
    subscribe() {
      this.unsubs.push(
        this.subCustomer(this.lgaCode),
        this.subSubscriptions(this.lgaCode),
        this.subInvoices(this.lgaCode)
      );
    },

    subCustomer(lgaCode) {
      const docRef = doc(db, "customers", lgaCode);
      return onSnapshot(
        docRef,
        (snapshot) => {
          this.customer = oneFromSnapshot(snapshot);
        },
        (e) => {
          this.errorToast(`Failed to subscribe to customer: ${e.message}`);
        }
      );
    },

    subSubscriptions(lgaCode) {
      const colRef = collection(db, "customers", lgaCode, "subscriptions");
      return onSnapshot(
        colRef,
        (snapshot) => {
          this.subscriptions = manyFromSnapshot(snapshot);
        },
        (e) => {
          this.errorToast(`Failed to subscribe to customer subscriptions: ${e.message}`);
        }
      );
    },

    subInvoices(lgaCode) {
      const colRef = collection(db, "customers", lgaCode, "invoices");
      return onSnapshot(
        colRef,
        (snapshot) => {
          this.invoices = manyFromSnapshot(snapshot);
        },
        (e) => {
          this.errorToast(`Failed to subscribe to customer invoices: ${e.message}`);
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem;

  .label {
    color: #212529;
    font-size: 0.9rem;
    font-weight: lighter;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
    margin-right: 30px;
  }
}
.badge {
  letter-spacing: 0.06rem;
  text-transform: uppercase;
  padding: 7px 10px;
}
</style>
