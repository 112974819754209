import { db } from "@/firebase/config";
import { manyFromSnapshot, oneFromSnapshot } from "@/firebase/firestore-helpers";
import { collection, doc, getDocsFromServer, limit, orderBy, query, loadBundle, getDocs, setDoc, deleteDoc, where, collectionGroup, getDoc, addDoc, onSnapshot } from "firebase/firestore";
import { deserialiseGeoJSON } from "clozure-shared/geometry";
import { haversineDistance, latLngToArray, pointInGeoJSON } from "@/util";

export default {
    namespaced: true,

    state: {
        activeLGA: null,
        lgaData: [],
        latestServerImport: null,
        latestImportTimestamp: null,
        staffAccessRequests: [],
    },

    mutations: {
        SET_ACCESS_REQUESTS(state, payload) {
            state.staffAccessRequests = payload;
        },

        SET_LGA_DATA(state, payload) {
            state.lgaData = payload;
        },
    },

    actions: {
        loadCachedImportTimestamp({ state }) {
            state.latestImportTimestamp = parseInt(localStorage.getItem("latest_import_timestamp", 0));
        },

        setLatestImportTimestamp({ state }, timestamp) {
            state.latestImportTimestamp = timestamp;
            localStorage.setItem("latest_import_timestamp", timestamp);
        },

        async fetchLatestServerImport({ state }) {
            state.latestImportTimestamp = parseInt(localStorage.getItem("latest_import_timestamp", 0));
            const q = query(collection(db, "lga_data_imports"), orderBy("timestamp", "desc"), limit(1));
            const snapshot = await getDocsFromServer(q);
            if (snapshot.empty) throw new Error("No LGA data imports configured on the server");
            const latest = snapshot.docs[0].data();
            state.latestServerImport = latest;
        },

        async importLatestServerBundle({ state, dispatch }) {
            const { timestamp, downloadURL } = state.latestServerImport;
            const response = await fetch(downloadURL);
            await loadBundle(db, response.body);
            await dispatch("setLatestImportTimestamp", timestamp);
        },

        async loadAllLGAData({ commit }) {
            const snapshot = await getDocs(collection(db, "lga_data"));
            const lgaData = manyFromSnapshot(snapshot);
            lgaData.forEach((lga) => lga.geometry = deserialiseGeoJSON(lga.geometry));
            commit("SET_LGA_DATA", lgaData);
            return lgaData;
        },

        async loadSingleLGA({ state }, code) {
            const docRef = doc(db, "lga_data", code);
            const snapshot = await getDoc(docRef);
            // const snapshot = await getDocFromCache(docRef);
            const activeLga = oneFromSnapshot(snapshot);
            activeLga.geometry = deserialiseGeoJSON(activeLga.geometry);
            state.activeLGA = activeLga;
            return activeLga;
        },

        async lgaFromCoordinates({ state, getters, dispatch }, target) {
            if (!target) throw new Error(`Invalid coordinates: ${target}`);
            if (!getters.cached) {
                // we should be cached by now, lets clear the latest update timestamp
                // to trigger another LGA data install in case the local data is corrupt
                await dispatch("setLatestImportTimestamp", null);
                throw new Error(`LGA data not cached`);
            }
            // map distances and sort nearest to furthest
            const sorted = state.lgaData
                .map((lga) => ({
                    ...lga,
                    distance: haversineDistance(target, lga.properties.geo_point_2d),
                }))
                .sort((a, b) => a.distance - b.distance);
            // find first match
            for (const lga of sorted)
                if (pointInGeoJSON(target, lga.geometry))
                    return lga;
            return null;
            // return dummy if nothing is found
            // return { properties: { lga_name: "Not in an Australian LGA" } };
        },

        async lgaFromLatLng({ dispatch }, latLng) {
            const target = latLngToArray(latLng);
            return dispatch("lgaFromCoordinates", target);
        },

        async saveManagementRequest(context, accessRequest) {
            const { lga: { lga_code } } = accessRequest;
            const colRef = collection(db, "lga_data", lga_code, "access_requests");
            await addDoc(colRef, accessRequest);
        },

        async removeManagementRequest(context, accessRequest) {
            const { id, lga: { lga_code } } = accessRequest;
            const docRef = doc(db, "lga_data", lga_code, "access_requests", id);
            await deleteDoc(docRef);
        },

        async subscribeToAccessRequests({ commit }, lgaCode) {
            const colRef = collection(db, "lga_data", lgaCode, "access_requests");
            return onSnapshot(colRef, (snapshot) => {
                commit("SET_ACCESS_REQUESTS", manyFromSnapshot(snapshot));
            });
        },

        assignManager(context, { lga_code, assignment }) {
            return setDoc(doc(db, "lga_data", lga_code, "managers", assignment.email), assignment)
        },

        removeManager(context, { id, lga_code }) {
            return deleteDoc(doc(db, "lga_data", lga_code, "managers", id));
        },

        async lgaManagers(context, lgaCode) {
            const colRef = collection(db, "lga_data", lgaCode, "managers");
            const snapshot = await getDocs(colRef);
            return manyFromSnapshot(snapshot);
        },

        async managedLGAs(context, email) {
            const q = query(collectionGroup(db, "managers"), where("email", "==", email));
            const snapshot = await getDocs(q);
            return manyFromSnapshot(snapshot);
        },

        async canManageLGA({ rootGetters }, lga_code) {
            const collectionRef = collection(db, "lga_data", lga_code, "managers");
            const { email } = rootGetters["auth/user"];
            const q = query(collectionRef, where("email", "==", email), where("roles", "array-contains-any", ["account_manager", "closure_administrator"]))
            const snapshot = await getDocs(q);
            return !snapshot.empty;
        },

        async canModerateLGA({ rootGetters }, lga_code) {
            const collectionRef = collection(db, "lga_data", lga_code, "managers");
            const { email } = rootGetters["auth/user"];
            const q = query(collectionRef, where("email", "==", email), where("roles", "array-contains-any", ["account_manager", "closure_administrator", "submission_moderator"]));
            const snapshot = await getDocs(q);
            return !snapshot.empty;
        },

        async lgaFromEmailSuffix(context, suffix) {
            const colRef = collection(db, "lga_data");
            const q = query(colRef, where("properties.emailSuffix", "==", suffix));
            const snapshot = await getDocs(q);
            let lga = null;
            if (snapshot.size > 0) lga = snapshot.docs[0].data().properties;
            return lga;
            // return getters.lgaProperties.find((lga) => lga.email.includes(suffix));
        },
    },

    getters: {
        // raw or processed data
        lgaData: (state) => state.lgaData,
        lgaProperties: (state) => state.lgaData.map((lga) => lga.properties),
        geometry: (state) => state.lgaData.map((lga) => lga.geometry),
        latestImportTimestamp: (state) => state.latestImportTimestamp,
        activeLGA: (state) => state.activeLGA,
        // computed metadata
        initialised: (state) => state.latestServerImport != null,
        cached: (state, getters) => getters.initialised && state.lgaData.length,
        updateRequired: (state, getters) => getters.initialised && state.latestServerImport?.timestamp != getters.latestImportTimestamp,
        staffAccessRequests: (state) => state.staffAccessRequests,
    },
};