import moment from "moment";
import Vue from "vue";

export default {
    namespaced: true,

    store: {
        online: false,
        lastOnline: moment().unix(),
    },

    mutations: {
        SET_ONLINE_STATE(state, payload) {
            Vue.set(state, "online", payload);
            if (payload == false) Vue.set(state, "lastOnline", moment().unix());
        }
    },

    actions: {
        setOnlineState({ commit }, payload) {
            commit("SET_ONLINE_STATE", payload);
        },
    },
}