<template>
  <div></div>
</template>
<script>
import { StaffRoles } from "@/enums/StaffRoles";
export default {
  computed: {
    staffRoles() {
      return StaffRoles;
    },
  },
};
</script>
