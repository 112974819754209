<template>
  <div></div>
</template>

<script>
export default {
  name: "connection-monitor-mixin",
  beforeMount() {
    this.$store.dispatch("connection/setOnlineState", navigator.onLine);
    window.addEventListener("online", this.onOnline);
    window.addEventListener("offline", this.onOffline);
  },
  beforeUnmount() {
    window.removeEventListener("online", this.onOnline);
    window.removeEventListener("offline", this.onOffline);
  },
  methods: {
    onOnline() {
      console.debug("Network connection established. Clozure is online.");
      this.$store.dispatch("connection/setOnlineState", true);
    },
    onOffline() {
      console.debug("Network connection failed. Clozure is offline.");
      this.$store.dispatch("connection/setOnlineState", false);
    },
  },
};
</script>
