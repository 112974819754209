export const StaffRoles = [
    {
        value: "account_manager",
        text: "Account Manager",
        description: "Manages customer billing information and user role assignment"
    },
    {
        value: "closure_administrator",
        text: "Closure Administrator",
        description: "Administers the closures and incidents for the local government area"
    },
    {
        value: "submission_moderator",
        text: "Submission Moderator",
        description: "Moderates submissions of incident reports from the general public"
    },
    {
        value: "media_comms_manager",
        text: "Media & Communications Manager",
        description: "Exports data as maps and publishable information for communications"
    },
];
