<template>
  <div class="select-all-checkbox-stack">
    <b-checkbox
      :checked="allSelected"
      :indeterminate="someSelected"
      @change="onSelectAll"
      :switch="switches"
    >
      {{ selectAllText }}
    </b-checkbox>
    <b-checkbox
      v-for="(opt, index) of options"
      :key="index"
      v-model="selection"
      :value="field ? opt[field] : opt"
      :checked="selection?.includes(field ? opt[field] : opt)"
      :switch="switches"
    >
      <slot name="label" v-bind:item="opt">
        {{ label ? opt[label] : opt }}
      </slot>
    </b-checkbox>
  </div>
</template>

<script>
export default {
  name: "select-all-checkbox-stack",

  props: ["options", "field", "label", "value", "switches"],

  data: () => ({
    selection: [],
  }),

  watch: {
    value() {
      if (this.value) this.selection = this.value;
    },
    selection() {
      this.$emit("input", this.selection);
    },
    options: {
      deep: true,
      handler: function (newVal, oldVal) {
        if (!(newVal && oldVal)) return;

        const filterFn = (arr) => (x) =>
          !arr.find((y) => {
            if (this.field) return x[this.field] == y[this.field];
            return x == y;
          });

        let diff = newVal
          .filter(filterFn(oldVal))
          .concat(oldVal.filter(filterFn(newVal)));

        if (diff.length == 0) return;

        if (newVal.length > oldVal.length) {
          diff.forEach((opt) => {
            const value = this.field ? opt[this.field] : opt;
            if (!this.selection.includes(value)) this.selection.push(value);
          });
        } else {
          diff.forEach((opt) => {
            const value = this.field ? opt[this.field] : opt;
            const index = this.selection?.indexOf(value);
            if (index > -1) this.selection.splice(index, 1);
          });
        }
      },
    },
  },

  computed: {
    allSelected() {
      return this.selection.length == this.options.length;
    },
    someSelected() {
      return this.selection.length > 0 && this.selection.length < this.options.length;
    },
    selectAllText() {
      return this.allSelected ? "Select None" : "Select All";
    },
  },

  mounted() {
    if (this.value) this.selection = this.value;
  },

  methods: {
    onSelectAll(selected) {
      if (!selected) {
        this.selection = [];
        return;
      }
      this.selection = this.field ? this.options.map((o) => o[this.field]) : this.options;
    },
  },
};
</script>

<style scoped>
/* >>> .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  transform: translate(0.75rem, -50%);
} */

/* >>> .custom-control-label::before,
>>> .custom-control-label::after {
  top: 50%;
  transform: translateY(-50%);
} */

>>> .custom-control {
  padding-left: 3rem;
}
>>> .custom-control-label {
  padding-left: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
}
</style>
