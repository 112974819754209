
import { db, auth } from '@/firebase/config'
import { manyFromSnapshot, oneFromSnapshot } from '@/firebase/firestore-helpers'
import { chunkArray } from '@/util'
import { collection, getDocs, setDoc, doc, query, where, onSnapshot, getDoc } from 'firebase/firestore'
import moment from 'moment'

export default {
  namespaced: true,

  state: () => ({
    incidentReports: [],
    activeReport: null,
  }),

  mutations: {
    CLEAR_REPORTS(state) {
      state.incidentReports = []
    },
    SET_REPORTS(state, reports) {
      state.incidentReports = reports;
    },
    MERGE_REPORTS(state, reports) {
      reports.forEach((r) => {
        let index = state.incidentReports.findIndex((report) => report.id == r.id);
        if (index > -1) {
          state.incidentReports[index] = r;
        } else {
          state.incidentReports.push(r);
        }
      });
    },
    ADD_REPORT(state, report) {
      state.incidentReports.push(report)
    },
    UPDATE_REPORT(state, report) {
      const index = state.incidentReports.findIndex(r => r.id == report.id)
      if (index == -1) {
        state.incidentReports.push(report)
      } else {
        state.incidentReports[index] = report
        state.incidentReports = [...state.incidentReports]
      }
    },
    REMOVE_REPORT(state, report) {
      const index = state.incidentReports.findIndex(r => r.id == report.id)
      if (index == -1) return
      state.incidentReports.splice(index, 1)
    },
    SET_ACTIVE_REPORT(state, report) {
      state.activeReport = report;
    },
  },

  actions: {
    async loadIncidentReport(context, reportId) {
      const docRef = doc(db, "incident_reports", reportId);
      const snapshot = await getDoc(docRef);
      const report = oneFromSnapshot(snapshot);
      return report;
    },

    async loadUserIncidentReports({ commit }, userId) {
      commit("CLEAR_REPORTS");
      const q = query(collection(db, "incident_reports"), where('source.payload.uid', '==', userId));
      const snapshot = await getDocs(q);
      const reports = manyFromSnapshot(snapshot);
      commit("SET_REPORTS", reports);
      return reports;
    },

    async subscribeToMyReports({ commit }) {
      const uid = auth.currentUser?.uid;
      if (!uid) throw new Error("Can't subscribe to user reports if they're not logged in");
      const q = query(collection(db, "incident_reports"), where('source.payload.uid', '==', uid));
      return onSnapshot(q, (snapshot) => commit("SET_REPORTS", manyFromSnapshot(snapshot)));
    },

    subscribeToLGA({ commit }, lgaCode) {
      return onSnapshot(
        query(collection(db, "incident_reports"), where("lga.lga_code", "==", lgaCode), where("status", "!=", "retracted")),
        (snapshot) => commit("SET_REPORTS", manyFromSnapshot(snapshot))
      )
    },

    subscribeToReport({ commit }, reportId) {
      return onSnapshot(doc(db, "incident_reports", reportId), (snapshot) => commit("SET_ACTIVE_REPORT", oneFromSnapshot(snapshot)));
    },

    async loadIncidentReports({ commit }, lgasWithinBounds) {
      commit("CLEAR_REPORTS");
      const snapshots = await Promise.all(
        chunkArray(lgasWithinBounds, 10)
          .map(chunk => {
            const q = query(collection(db, 'incident_reports'), where('lga.lga_code', 'in', chunk), where("status", "!=", "retracted"))
            return getDocs(q)
          })
      );
      const reports = snapshots.flatMap(manyFromSnapshot);
      commit('SET_REPORTS', reports)
      return reports
    },

    async saveIncidentReport({ commit }, incident) {
      const docRef = doc(db, 'incident_reports', incident.id)
      incident.updatedAt = moment().unix()
      await setDoc(docRef, incident, { merge: true })
      commit("UPDATE_REPORT", incident);
      return incident
    },

    async retractIncident(context, incident) {
      console.log(`[incident-reports] retractIncident`, { incident });

      incident.status = "retracted"
      incident.retractedAt = moment().unix()
      return context.dispatch('saveIncidentReport', incident);
    },

    async checkCustomerSubscriptionValidity(context, lga_code) {
      const hasActiveSubscription = await context.dispatch("customers/hasActiveSubscription", lga_code, { root: true });
      if (!hasActiveSubscription) {
        throw new Error("LGA does not have an active subscription");
      }
    },

    async approveIncident(context, { incident, reason, moderator, condition, metadata }) {
      await context.dispatch("checkCustomerSubscriptionValidity", incident.lga.lga_code);

      // add to incident activity log
      incident.status = "approved"
      incident.activity = [
        ...(incident.activity || []),
        { what: "approved", who: moderator, when: moment().unix(), why: reason },
      ];

      if (!incident.closureId) {
        const closure = {
          active: true,
          condition: condition,
          position: incident.position,
          nearestLocation: incident.nearestLocation || {},
          formattedAddress: incident.formattedAddress || null,
          lga: incident.lga,
          type: incident.type,
          metadata,
          reason: incident.description,
          media: incident.media || null,
          createdAt: moment().unix(),
          reported: true,
          source: incident.source,
          incidentId: incident.id
        };

        // create a new closure item from this incident
        closure.id = await context.dispatch('closureWrite/create', closure, { root: true });
        incident.closureId = closure.id;

        // add reference to this new closure to the media item
        for (var mediaItem of closure.media) {
          await context.dispatch("media/addReference", {
            mediaId: mediaItem.id,
            collectionName: "closures",
            documentId: closure.id,
            field: "media",
          }, { root: true });
        }
      }

      // save closure and incident report
      return context.dispatch('saveIncidentReport', incident)
    },

    async denyIncident(context, { incident, reason, moderator }) {
      await context.dispatch("checkCustomerSubscriptionValidity", incident.lga.lga_code);

      incident.status = "denied"
      incident.activity = [
        ...(incident.activity || []),
        { what: "denied", who: moderator, when: moment().unix(), why: reason },
      ];

      return context.dispatch('saveIncidentReport', incident)
    },

    clearState({ state }) {
      console.debug("[incidentReports] clearing state");
      state.incidentReports = [];
    },
  },

  getters: {
    myReports: (state) => state.incidentReports
      .filter((report) => report.source.payload.uid === auth.currentUser?.uid)
      .sort((a, b) => a.createdAt > b.createdAt),

    incidentReports(state) {
      return state.incidentReports
        .sort((a, b) => a.createdAt > b.createdAt)
    },

    relevantIncidentReports: (state) => (user) => {
      if (!user) return [];
      const staff = (r) => (r.status == 'pending')
      const guest = (r) => (r.source.payload.uid == user.uid && r.status == 'pending')
      return state.incidentReports
        .filter(user.staff ? staff : guest)
        .sort((a, b) => a.createdAt > b.createdAt)
    },

    incidentReportsByStatus(state) {
      // const statuses = [...new Set(state.incidentReports.map(r => r.status))]
      const statuses = ["pending", "approved", "denied"]
      const result = {}
      for (var key of statuses) {
        result[key] = state.incidentReports
          .filter(r => r.status == key)
          .sort((a, b) => a.createdAt - b.createdAt)
      }
      return result
    },

    activeReport: (state) => state.activeReport,
  },
}