<script>
const opts = {
  info: {
    title: "Notification",
    variant: "info",
  },
  error: {
    title: "Error",
    variant: "danger",
  },
  success: {
    title: "Success",
    variant: "success",
  },
  warning: {
    title: "Warning",
    variant: "warning",
  },
};
export default {
  name: "app-toasts",
  methods: {
    errorToast(message, options = {}) {
      if (process.env.VUE_APP_ENV === "demo") {
        console.debug("suppressed errors in demo:", message);
        return;
      }
      this.$bvToast.toast(message, { ...opts.error, ...options });
    },
    infoToast(message, options = {}) {
      this.$bvToast.toast(message, { ...opts.info, ...options });
    },
    successToast(message, options = {}) {
      this.$bvToast.toast(message, { ...opts.success, ...options });
    },
    warningToast(message, options = {}) {
      this.$bvToast.toast(message, { ...opts.warning, ...options });
    },
  },
};
</script>
