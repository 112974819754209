<script>
export default {
  name: "app-update",

  data: () => ({
    registration: null,
    updateExists: false,
  }),

  created() {
    document.addEventListener("swUpdated", this.updateAvailable, { once: true });
  },

  beforeDestroy() {
    document.removeEventListener("swUpdated", this.updateAvailable);
  },

  methods: {
    updateAvailable(event) {
      this.registration = event?.detail;
      this.updateExists = true;

      this.$nextTick(() => {
        let msg = `An update is available.`;
        if (this.isMobile) {
          msg += ` Please close all instances of Clozure and re-open the app to use the newest version.`;
        } else {
          msg += ` Please perform a hard refresh (Ctrl + F5) to reload the app and use the newest version.`;
        }

        this.warningToast(msg, {
          title: "Updates Installed",
          noAutoHide: true,
        });
      });
    },
  },
};
</script>
