<template>
  <div>
    <div v-if="!notificationsAvailable">
      <p class="lead">Notifications are not available in your browser.</p>
      <p>
        Please use a compatible browser if you would like to opt-in to push notifications.
      </p>
    </div>
    <div v-else>
      <div v-if="!fcmEnabled">
        <p>You can subscribe to receive push notifications on this device.</p>

        <b-alert variant="danger" :show="notificationPermissionDenied">
          <p>
            <strong>Note:</strong> Permission to send push notifications to this device
            has been previously denied.
          </p>

          <p class="mb-0">
            To undo this, please reset the notification permissions in your browser. This
            can be done in the 'Site information' menu, accessible via the padlock icon in
            the left hand side of the address bar. This may differ across different web
            browsers.
          </p>
        </b-alert>

        <b-overlay
          rounded
          opacity="0.6"
          spinner-small
          class="d-inline-block"
          :show="fcmLoading"
        >
          <b-button
            variant="outline-primary"
            @click="onEnableMessaging"
            :disabled="isDemo || notificationPermissionDenied"
            v-b-tooltip.focus
            :title="isDemo ? 'Disabled in the demo' : ''"
          >
            <fa-icon icon="bell" class="mr-2"></fa-icon>
            Enable Push Notifications
          </b-button>
        </b-overlay>
      </div>
      <div v-if="fcmEnabled">
        <p>You are currently subscribed to receive notifications on this device.</p>
        <div v-if="notProduction">
          <p><strong>Your Token:</strong> {{ fcmToken }}</p>
          <p>You can use this token to test notifications via the Firebase Console.</p>
        </div>
        <b-overlay
          rounded
          opacity="0.6"
          spinner-small
          class="d-inline-block"
          :show="fcmLoading"
        >
          <b-button variant="outline-primary" @click="onDisableMessaging">
            <fa-icon icon="bell-slash" class="mr-2"></fa-icon>
            Disable Push Notifications
          </b-button>
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import FirebaseCloudMessaging from "@/mixins/FirebaseCloudMessaging";

export default {
  name: "user-notification-options-edit-form",

  mixins: [FirebaseCloudMessaging],

  computed: {
    notProduction() {
      return process.env.VUE_APP_ENV !== "production";
    },
  },
};
</script>

<style lang="scss" scoped></style>
