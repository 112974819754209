import { db } from "@/firebase/config";
import { doc, getDoc, setDoc, onSnapshot, query, collection, getCountFromServer, where, updateDoc, increment, deleteField } from "firebase/firestore";
import Vue from "vue";

export default {
    namespaced: true,

    state: {
        closures: {},
        localGovernmentAreas: {},
    },

    mutations: {
        ADD_CLOSURE(state, { closureId, payload }) {
            Vue.set(state.closures, closureId, payload)
        },
        SET_CLOSURES(state, payload) {
            state.closures = payload
        },
        REMOVE_CLOSURE(state, closureId) {
            Vue.delete(state.closures, closureId);
        },

        ADD_LGA(state, { lgaCode, payload }) {
            Vue.set(state.localGovernmentAreas, lgaCode, payload);
        },
        SET_LGAS(state, payload) {
            state.localGovernmentAreas = payload;
        },
        REMOVE_LGA(state, lgaCode) {
            Vue.delete(state.localGovernmentAreas, lgaCode);
        },
    },

    actions: {
        async subscribeToClosure({ rootGetters, commit }, closure) {
            // load user subs
            const { uid } = rootGetters["auth/serialisedUser"];
            const { closures } = await fetchSubscriptions(uid);

            // make update data from closure
            const { id, formattedAddress, lga, type } = closure;
            const { lga_code, lga_name } = lga;
            const payload = { formattedAddress, type, lga: { lga_code, lga_name }, closureId: id, };
            closures[id] = payload;

            // save changes
            const docRef = doc(db, "subscriptions", uid);
            await setDoc(docRef, { closures }, { merge: true });
            commit("ADD_CLOSURE", { payload, closureId: id });

            await incrementSubscriberCount(id);
        },

        async unsubscribeFromClosure({ commit, rootGetters }, closure) {
            const { uid } = rootGetters["auth/serialisedUser"];
            const docRef = doc(db, "subscriptions", uid);
            await updateDoc(docRef, {
                ['closures.' + closure.id]: deleteField(),
                ['subscriptions.' + closure.id]: deleteField(), // TODO: remove this after all users have been migrated
            });
            commit("REMOVE_CLOSURE", closure.id);
            await decrementSubscriberCount(closure.id);
        },

        async subscribeUserToLga({ rootGetters, commit }, payload) {
            // load user subs
            const { uid } = rootGetters["auth/serialisedUser"];
            const { localGovernmentAreas } = await fetchSubscriptions(uid);

            // package the payload
            const lgaCode = payload?.lga?.lga_code;
            localGovernmentAreas[lgaCode] = payload;

            // save changes
            const docRef = doc(db, "subscriptions", uid);
            await setDoc(docRef, { localGovernmentAreas }, { merge: true });
            commit("ADD_LGA", { payload, lgaCode });
        },

        async unsubscribeUserFromLga({ commit, rootGetters }, lgaCode) {
            const { uid } = rootGetters["auth/serialisedUser"];
            const docRef = doc(db, "subscriptions", uid);
            await updateDoc(docRef, {
                ['localGovernmentAreas.' + lgaCode]: deleteField(),
            });
            commit("REMOVE_LGA", lgaCode);
        },

        async subscribeToUserSubscriptions({ commit }, userId) {
            if (!userId) throw new Error("userId is required to subscribe to user subscriptions");

            const docRef = doc(db, "subscriptions", userId);
            return onSnapshot(docRef, (snapshot) => {
                const data = snapshot.data();

                // TODO: remove this fallback after all users have been migrated
                const closures = {
                    ...(data.subscriptions || {}),
                    ...(data.closures || {}),
                }

                commit("SET_CLOSURES", closures || {});
                commit("SET_LGAS", data.localGovernmentAreas || {});
            });
        },

        async loadClosureSubs({ commit }, userId) {
            const { closures } = await fetchSubscriptions(userId);
            commit("SET_CLOSURES", closures);
        },

        async unsubscribeFromAllClosures({ commit }, userId) {
            // decrement all closure doc subscriberCounts
            const { closures } = await fetchSubscriptions(userId);
            console.debug(`[unsubscribeFromAllClosures] unsubscribing from ${Object.keys(closures).length} closures for user ${userId}`);
            await Promise.all(Object.values(closures)?.map((sub) => decrementSubscriberCount(sub.closureId)));

            // clear the subscriptions map on the user's document
            const docRef = doc(db, "subscriptions", userId);
            await setDoc(docRef, {
                closures: {},
                subscriptions: {} // TODO: remove this after all users have been migrated
            }, { merge: true });
            commit("SET_CLOSURES", {});
        },

        async unsubscribeFromAllLgas({ commit }, userId) {
            // clear the subscriptions map on the user's document
            const docRef = doc(db, "subscriptions", userId);
            await setDoc(docRef, { localGovernmentAreas: {} }, { merge: true });
            commit("SET_LGAS", {});
        },

        clearState({ state }) {
            console.debug("[subscriptions] clearing state");
            state.closures = {};
            state.localGovernmentAreas = {};
        },

        async countSubsOnClosure(context, closureId) {
            const q = query(collection(db, "subscriptions"), where(`closures.${closureId}.closureId`, "==", closureId));
            const count = await getCountFromServer(q);
            return count;
        },

        async countSubsOnLga(context, lgaCode) {
            const q = query(collection(db, "subscriptions"), where(`localGovernmentAreas.${lgaCode}.lgaCode`, "==", lgaCode));
            const count = await getCountFromServer(q);
            return count;
        }
    },

    getters: {
        closures: (state) => state.closures,
        localGovernmentAreas: (state) => state.localGovernmentAreas,

        closuresArray: (state) => Object.keys(state.closures || {}).map(closureId => {
            return { closureId, ...state.closures[closureId] }
        }),

        lgasArray: (state) => Object.keys(state.localGovernmentAreas || {}).map(lgaCode => {
            return { lgaCode, ...state.localGovernmentAreas[lgaCode] }
        }),

        subscribedToClosure: (state) => (closure) => {
            return Boolean((state.closures || {})[closure.id]);
        },

        subscribedToLga: (state) => (lgaCode) => {
            return Boolean((state.localGovernmentAreas || {})[lgaCode]);
        },

        countClosures: (state) => Object.keys(state.closures || {}).length,
        countLgas: (state) => Object.keys(state.localGovernmentAreas || {}).length,

        MAX_LGA_COUNT: () => 5,
    }
}

async function fetchSubscriptions(uid) {
    const docRef = doc(db, "subscriptions", uid);
    const snapshot = await getDoc(docRef);

    if (!snapshot.exists()) {
        return {
            closures: {},
            localGovernmentAreas: {},
        };
    }

    const data = snapshot.data();

    // TODO: remove this fallback after all users have been migrated
    return {
        closures: {
            ...(data.subscriptions || {}),
            ...(data.closures || {}),
        },
        localGovernmentAreas: data.localGovernmentAreas || {},
    };
}

async function incrementSubscriberCount(closureId) {
    const docRef = doc(db, "closures", closureId);
    await updateDoc(docRef, { subscriberCount: increment(1) });
}

async function decrementSubscriberCount(closureId) {
    const docRef = doc(db, "closures", closureId);
    await updateDoc(docRef, { subscriberCount: increment(-1) });
}