<template>
  <div class="dashboard-public-view">
    <h1>Dashboard</h1>

    <template v-if="lga">
      <div
        class="d-flex justify-content-between align-items-center"
        :class="{ 'flex-wrap': isMobile }"
      >
        <h4 class="mb-0">
          <span class="font-weight-lighter">Your LGA:</span> {{ lga?.lga_name }}
        </h4>
        <p class="lead mb-0">
          Administered by
          <b-link :href="`https://${lga?.emailSuffix}`" target="_blank">
            {{ lga.lga_name_long }}
          </b-link>
        </p>
      </div>

      <hr />

      <b-card v-if="!hasActiveSubscription" class="mb-3" no-body>
        <b-card-header>
          <h5 class="mb-0">Not Using Clozure</h5>
        </b-card-header>
        <b-card-body>
          <p class="lead">
            {{ lga?.lga_name_long }} are not currently signed up as users of Clozure.
          </p>

          <div v-if="requestIsSignable">
            <p>
              If you'd like your local council to get on board, please click the button
              below to add your name to the request.
            </p>
            <b-button variant="outline-primary" @click="signRequest(lga)">
              Sign request
            </b-button>
          </div>

          <div v-if="!requestIsSignable">
            <p class="mb-0">
              Thanks for adding your name to the request. You will receive a notification
              when your council signs up.
            </p>
          </div>
        </b-card-body>
      </b-card>

      <div
        v-if="hasActiveSubscription && initialised"
        class="d-flex flex-wrap"
        style="gap: 15px"
      >
        <DashboardCardLgaClosureSummary :lga="lga" />
        <DashboardCardLgaRecentClosureChanges :lga="lga" />
      </div>
    </template>

    <div class="d-flex flex-wrap mt-3" style="gap: 15px">
      <DashboardCardUserProfile />
      <DashboardCardUserSubscriptions />
    </div>
    <div class="d-flex my-3" :class="{ 'flex-wrap': isMobile }" style="gap: 15px">
      <DashboardCardInstallPwa />
      <DashboardCardPushNotifications />
    </div>
  </div>
</template>

<script>
import DashboardCardLgaClosureSummary from "@/components/DashboardCardLgaClosureSummary";
import DashboardCardLgaRecentClosureChanges from "@/components/DashboardCardLgaRecentClosureChanges";
import DashboardCardPushNotifications from "@/components/DashboardCardPushNotifications";
import DashboardCardInstallPwa from "@/components/DashboardCardInstallPwa";
import DashboardCardUserProfile from "@/components/DashboardCardUserProfile";
import DashboardCardUserSubscriptions from "@/components/DashboardCardUserSubscriptions";
import { db, auth } from "@/firebase/config";
import { doc, getDoc, setDoc, arrayUnion } from "firebase/firestore";

export default {
  name: "dashboard-public-view",

  components: {
    DashboardCardLgaClosureSummary,
    DashboardCardLgaRecentClosureChanges,
    DashboardCardPushNotifications,
    DashboardCardInstallPwa,
    DashboardCardUserProfile,
    DashboardCardUserSubscriptions,
  },

  data: () => ({
    request: null,
    hasActiveSubscription: false,
    initialised: false,
  }),

  computed: {
    user() {
      return auth.currentUser;
    },

    lga() {
      return this.$store.getters["userData/profile"]?.lga;
    },

    requestIsSignable() {
      if (!this.initialised) return false;
      if (this.hasActiveSubscription) return false;
      if (!this.request?.signatories) return true;
      const { signatories } = this.request;
      const matchFound = signatories.find((s) => s.uid === this.user.uid);
      return !matchFound;
    },
  },

  watch: {
    async lga() {
      if (this.lga) {
        await this.loadRequest(this.lga);
        await this.checkSubscription(this.lga);
      }
    },
  },

  async mounted() {
    if (this.lga) {
      await this.loadRequest(this.lga);
      await this.checkSubscription(this.lga);
    }
  },

  methods: {
    async loadRequest(lga) {
      const docRef = doc(db, "lga_requests", lga?.lga_code);
      try {
        const snapshot = await getDoc(docRef);
        if (snapshot.exists()) this.request = snapshot.data();
      } catch (e) {
        this.errorToast(`Failed to load request for ${lga?.lga_code}: ${e.message}`);
      }
    },

    async checkSubscription(lga) {
      this.hasActiveSubscription = await this.$store.dispatch(
        "customers/hasActiveSubscription",
        lga.lga_code
      );
      this.initialised = true;
    },

    async signRequest(lga) {
      const { uid, displayName, email } = this.$store.getters["auth/serialisedUser"];
      const docRef = doc(db, "lga_requests", lga?.lga_code);
      try {
        await setDoc(
          docRef,
          { signatories: arrayUnion({ uid, displayName, email }) },
          { merge: true }
        );
        await this.loadRequest(lga);
      } catch (e) {
        this.errorToast(`Oops! Something went wrong signing the request: ${e.message}`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  flex-grow: 1;
}
</style>
