<script>
import { mapGetters } from "vuex";

export default {
  name: "dashboard-staff-card",

  computed: {
    ...mapGetters("userData", [
      "assignedLGAs",
      "managedLGAs",
      "moderatedLGAs",
      "administeredLGAs",
      "exportableLGAs",
    ]),

    lga() {
      return this.assignedLGAs?.[0];
    },
  },
};
</script>
