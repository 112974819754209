import { db, storage } from "@/firebase/config";
import { ref, deleteObject, uploadBytes, uploadBytesResumable } from "firebase/storage";
import { nameAndExtension } from "@/util";
import { setDoc, collection, doc, deleteDoc, getDoc, updateDoc, arrayUnion, arrayRemove } from "firebase/firestore";
import moment from "moment";

export default {

  namespaced: true,

  state: () => ({
    media: [],
    uploadTasks: [],
    completed: [],
  }),

  mutations: {
    SET_MEDIA(state, payload) {
      state.media = payload
    },
    ADD_MEDIA(state, payload) {
      state.media.push(payload)
    },
    REMOVE_MEDIA(state, id) {
      const index = state.media.findIndex(it => it.id == id)
      if (index == -1) {
        console.error(`media item with id ${id} not found`)
        return
      }
      if (state.media[index].references.length > 0)
        throw new Error("Cannot remove media item with existing references")
      state.media.splice(index, 1)
    },
    UPDATE_UPLOAD_TASK(state, task) {
      const index = state.uploadTasks.findIndex(t => t.id == task.id)
      if (index == -1) {
        state.uploadTasks.push(task)
      } else {
        state.uploadTasks[index] = {
          ...state.uploadTasks[index],
          ...task
        };
        state.uploadTasks = [...state.uploadTasks];
      }
    },
    COMPLETE_UPLOAD_TASK(state, taskId) {
      const task = state.uploadTasks.find(t => t.id == taskId);
      state.completed.push(task);
    },
    CLEAR_UPLOAD_STATE(state) {
      state.uploadTasks = []
      state.completed = []
    },
    ADD_REFERENCE(state, { id, reference }) {
      const index = state.media.findIndex(it => it.id == id)
      if (index == -1) {
        console.error(`media item with id ${id} not found`)
        return
      }
      state.media[index].references.push(reference)
      state.media = [...state.media]
    },
    REMOVE_REFERENCE(state, { id, reference }) {
      const itemIndex = state.media.findIndex(it => it.id == id)
      if (itemIndex == -1) {
        console.error(`media item with id ${id} not found`)
        return
      }
      const refIndex = state.media[itemIndex]?.references?.indexOf(reference)
      if (refIndex == -1) {
        console.error(`media item reference not found`, reference)
        return
      }
      state.media[itemIndex]?.references?.splice(refIndex, 1)
      state.media = [...state.media]
    },
  },

  actions: {
    async upload(context, { files }) {
      const results = [];
      for (const file of files) {
        // prepare file for upload
        const docRef = doc(collection(db, "media"))
        const id = docRef.id
        const { extension } = nameAndExtension(file.name);
        const filename = `${id}.${extension}`
        const storageRef = ref(storage, `media/${filename}`);

        // perform the upload
        await uploadBytes(storageRef, file)

        // save the document to firestore
        const mediaDoc = {
          extension,
          uploadedAt: moment().unix(),
          user: context.rootGetters["auth/serialisedUser"],
          resized: false,
          references: [],
        };
        await setDoc(docRef, mediaDoc, { merge: true })

        // prepare entry for document (incident/closure) media array
        const result = {
          id: id,
          ...mediaDoc,
        };

        context.commit("ADD_MEDIA", mediaDoc)
        results.push(result)
      }
      return results;
    },

    async uploadAsync(context, { files }) {
      for (const file of files) {
        // make references
        const docRef = doc(collection(db, "media"));
        const { extension } = nameAndExtension(file.name);
        const filename = `${docRef.id}.${extension}`;
        const storageRef = ref(storage, `media/${filename}`);

        // create event handlers
        const onSnapshot = (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          context.commit("UPDATE_UPLOAD_TASK", { filename, progress, extension, id: docRef.id, state: snapshot.state });
        }
        const onError = (e) => {
          context.commit("UPDATE_UPLOAD_TASK", { id: docRef.id, error: true, message: e.message });
        }
        const onComplete = async () => {
          const user = context.rootGetters["auth/serialisedUser"];
          const uploadedAt = moment().unix();
          const mediaDoc = { extension, user, uploadedAt, resized: false, references: [] };
          await setDoc(docRef, mediaDoc, { merge: true });
          context.commit("COMPLETE_UPLOAD_TASK", docRef.id);
        }

        // create upload task at the storage reference
        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on("state_changed", onSnapshot, onError, onComplete);
      }
    },

    async remove(context, id) {
      const docRef = doc(db, "media", id);
      const snapshot = await getDoc(docRef);
      const { extension } = snapshot.data();
      await Promise.all([
        deleteObject(ref(storage, `media/${id}.${extension}`)),
        deleteObject(ref(storage, `media/${id}_thumb.${extension}`)),
      ]);
      await deleteDoc(docRef);
      context.commit("REMOVE_MEDIA", id)
    },

    resetUploads({ commit }) {
      commit("CLEAR_UPLOAD_STATE")
    },

    async addReference(context, { mediaId, collectionName, documentId, field }) {
      const reference = { collection: collectionName, documentId: documentId, field: field }
      await updateDoc(doc(db, "media", mediaId), { references: arrayUnion(reference) })
      context.commit("ADD_REFERENCE", { id: mediaId, reference })
    },

    async removeReference(context, { mediaId, collectionName, documentId }) {
      const docRef = doc(db, "media", mediaId)
      const reference = { collection: collectionName, documentId: documentId }
      await updateDoc(docRef, { references: arrayRemove(reference) })
      context.commit("REMOVE_REFERENCE", { id: mediaId, reference })
      const snapshot = await getDoc(docRef);
      const mediaItem = snapshot.data();
      if (mediaItem.references.length == 0)
        await context.dispatch("remove", mediaId)
    },

  },

  getters: {
    media: (state) => state.media,
    uploadTasks: (state) => state.uploadTasks,
    complete: ({ completed, uploadTasks }) => completed.length === uploadTasks.length && completed.length > 0,
  },

}