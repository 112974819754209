import { functions } from "@/firebase/config";
import { reverseGeocode } from "@/google/geocode";
import { httpsCallable } from "firebase/functions";
const getDirections = httpsCallable(functions, "routing-getDirections");
import { polygonBoundsOverlap, polygonContainsOther } from "@/util";
import { bufferLatLngBounds } from "@/tools/geometry";
import { LatLngBounds } from "clozure-shared/geometry";

const state = {
    locations: [],
    avoidLocations: null,
    avoidPolygons: null,
    response: null,
    showNavigationSidebar: false,
    calculating: false,
    showDebugging: false,
    debugPolygons: [],
}

const mutations = {
    SET_RESPONSE(state, payload) {
        state.response = payload;
    },

    SET_SOURCE(state, payload) {
        const count = state.locations?.length > 1 ? 1 : 0;
        state.locations.splice(0, count, payload);
    },

    SET_DESTINATION(state, payload) {
        if (state.locations.length < 2) {
            state.locations.push(payload)
        } else {
            const index = state.locations.length - 1;
            state.locations.splice(index, 1, payload);
        }
    },

    ADD_VIA(state, payload) {
        state.locations.splice(state.locations.length - 1, 0, payload);
    },

    SET_SIDEBAR(state, payload) {
        state.showNavigationSidebar = payload;
    },

    SET_AVOID_POLYGONS(state, payload) {
        state.avoidPolygons = payload;
    },

    SET_AVOID_LOCATIONS(state, payload) {
        state.avoidLocations = payload;
    },

    UPDATE_LOCATION_ORDER(state, locations) {
        state.locations = locations;
        state.response = null;
    },

    REMOVE_LOCATION(state, address) {
        const index = state.locations.findIndex((l) => l.formattedAddress === address);
        if (index !== -1) {
            state.locations.splice(index, 1)
        }
    },

    MOVE_LOCATION(state, { index, location }) {
        state.locations.splice(index, 1, location);
    },

    SET_CALCULATING(state, payload) {
        state.calculating = payload;
    },

    SET_DEBUGGING(state, payload) {
        state.showDebugging = payload;
    },
}

const actions = {
    setAvoidPolygons({ commit }, polygons) {
        commit("SET_AVOID_POLYGONS", polygons);
    },

    setAvoidLocations({ commit }, locations) {
        commit("SET_AVOID_LOCATIONS", locations);
    },

    async getDirections(context) {
        context.commit("SET_RESPONSE", null);

        if (!context.getters.ready) {
            context.commit("SET_RESPONSE", { error: true, message: "You must provide at least 2 locations" })
        }

        let response;
        try {
            context.commit("SET_CALCULATING", true);

            // request directions until the resulting path is inside our calculated buffer from 'recalculateAvoids'
            let pathLeavesBounds;
            let funcResponse;
            let iterations = 0;
            let lastPath = [];
            const MaxIterations = 5;

            // first pass to establish an initial route to buffer
            funcResponse = await getDirections({ locations: context.state.locations.map((l) => l.coordinates) });
            console.debug("[navigation] getDirections firstPass", { funcResponse })
            if (funcResponse.data.error) throw new Error(funcResponse.data.message)
            lastPath = funcResponse.data.directions?.[0].feature.geometry.coordinates;

            do {
                // determine the new buffer and avoidPolygons from the lastPath
                console.debug(`[navigation] getDirections`, { lastPath, state: context.state })
                const { buffer, ...options } = recalculateAvoids(context.state, lastPath);

                // fetch with the new options for avoidLocations and avoidPolygons
                funcResponse = await getDirections(options);
                console.debug("[navigation] getDirections", { options, funcResponse })
                if (funcResponse.data.error) throw new Error(funcResponse.data.message)

                // check if the path leaves the buffer
                lastPath = funcResponse.data.directions[0].feature.geometry.coordinates;
                pathLeavesBounds = !polygonContainsOther(buffer, lastPath)
                console.debug("[navigation] getDirections", { pathLeavesBounds, iterations })

                // store the latest buffer and avoidPolygons for debugging; need to be reversed to [lat, lng]
                context.state.debugPolygons = [buffer, ...options.avoidPolygons]
                    .map((pl) => pl.map(([lng, lat]) => [lat, lng]))

                iterations++;
            } while (pathLeavesBounds && iterations < MaxIterations);

            response = funcResponse.data;
        } catch (e) {
            console.error(`Failed to calculate directions: ${e.message}`)
            response = {
                error: true,
                message: e.message,
            }
        } finally {
            context.commit("SET_CALCULATING", false);
            context.commit("SET_RESPONSE", response);
        }
    },

    async setSource({ commit }, coordinates) {
        commit("SET_SOURCE", await getFormattedAddress(coordinates));
        commit("SET_RESPONSE", null);
    },

    async setDestination({ commit }, coordinates) {
        commit("SET_DESTINATION", await getFormattedAddress(coordinates));
        commit("SET_RESPONSE", null);
    },

    async addVia({ commit }, coordinates) {
        commit("ADD_VIA", await getFormattedAddress(coordinates));
        commit("SET_RESPONSE", null);
    },

    async removeLocation({ commit }, coordinates) {
        commit("REMOVE_LOCATION", coordinates);
        commit("SET_RESPONSE", null);
    },

    async moveLocation(context, { index, coordinates }) {
        const location = await getFormattedAddress(coordinates);
        context.commit("MOVE_LOCATION", { index, location })
        context.commit("SET_RESPONSE", null);
        context.commit("SET_SIDEBAR", true);
        if (context.getters.ready) await context.dispatch("getDirections");
    },

    async overwriteLocation({ commit }, { index, location }) {
        commit("MOVE_LOCATION", { index, location });
        commit("SET_RESPONSE", null);
    },

    clearLocations({ state }) {
        state.locations = [];
    },

    clearResponse({ state }) {
        state.response = null;
    },
}

async function getFormattedAddress(coordinates) {
    const [lat, lng] = coordinates;
    const response = await reverseGeocode({ lat, lng });
    const formattedAddress = response?.results?.[0]?.formatted_address;
    return { formattedAddress, coordinates };
}

const getters = {
    ready: (state) => state.locations?.length >= 2,
    showNavigationSidebar: (state) => state.showNavigationSidebar,
    navigationLocations: (state) => state.locations,

    routeGeojson: (state) => {
        if (state.response?.directions) {
            return state.response.directions[0].feature;
        }
        return null;
    },

    calculating: (state) => state.calculating,
    avoidPolygons: (state) => state.avoidPolygons,
    debugPolygons: (state) => state.debugPolygons,
    showDebugging: (state) => state.showDebugging,
}

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced: true
}


function recalculateAvoids({ locations, avoidLocations, avoidPolygons }, previousPath = []) {
    // create a bounding box including all points
    const bounds = new LatLngBounds();
    for (const point of locations) {
        const [lat, lng] = point.coordinates;
        bounds.extend({ lat, lng });
    }

    // add each point of coordinates to the buffer
    for (const point of previousPath) {
        const [lng, lat] = point;
        bounds.extend({ lat, lng });
    }

    // buffer the bounding box
    const buffered = bufferLatLngBounds(bounds, 1e3);

    // extend the bounds with the new buffer points so we can filter down to avoidLocations that overlap
    for (const point of buffered)
        bounds.extend(point)

    // filter down to closures that overlap
    const relevantLocations = avoidLocations?.filter((point) => bounds.containsLatLng(point[0], point[1]))
    const relevantPolygons = avoidPolygons?.filter((polygon) => polygonBoundsOverlap(buffered, polygon))

    const options = {
        buffer: buffered.map(({ lat, lng }) => [lng, lat]), // return as [lng, lat] to match the avoidPolygons format
        locations: locations.map((l) => l.coordinates),
        avoidLocations: relevantLocations?.length ? relevantLocations : null,
        avoidPolygons: (relevantPolygons || []).map((pl) => pl.map(([lat, lng]) => [lng, lat])),
    };

    return options;
}