<template>
  <DashboardCard>
    <template #title>Subscribed Closures</template>
    <template #header-right>
      <b-badge v-if="subscriptionCount > 0" variant="primary">
        {{ subscriptionCount }}
      </b-badge>
    </template>
    <b-list-group flush>
      <b-list-group-item
        v-for="(closure, i) in closures"
        :key="i"
        :to="`/closures/${closure.id}`"
      >
        <div class="d-flex justify-content-between align-items-center">
          <b-img
            width="60"
            height="60"
            :src="`/img/markers/64x64/marker_${closure.type.value}_${closure.condition}.png`"
            class="mr-2"
            style="margin-left: -5px"
          />
          <div>
            <div>{{ closure.type.text }} {{ closure.nearestLocation?.name }}</div>
            <small>{{ closure.formattedAddress }}</small>
          </div>
          <div class="flex-grow-1"></div>
        </div>
      </b-list-group-item>
      <b-list-group-item v-if="closures?.length === 0">
        You are not subscribed to any closures.
      </b-list-group-item>
      <b-list-group-item to="/subscriptions" class="text-muted text-center">
        View All
      </b-list-group-item>
    </b-list-group>
  </DashboardCard>
</template>

<script>
import DashboardCard from "@/components/DashboardCard";
import { db, auth } from "@/firebase/config";
import {
  collection,
  doc,
  documentId,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { manyFromSnapshot } from "@/firebase/firestore-helpers";

export default {
  name: "dashboard-card-user-subscriptions",

  components: {
    DashboardCard,
  },

  mixins: [DashboardCard],

  data: () => ({
    subscriptions: [],
    closures: [],
  }),

  computed: {
    user() {
      return auth.currentUser;
    },

    subscriptionCount() {
      return this.subscriptions?.length;
    },
  },

  watch: {
    user() {
      if (this.user?.uid) this.subscribe();
    },

    async subscriptions(subs) {
      const closureIds = subs.map((s) => s.closureId);

      console.debug("[DashboardCardUserSubscriptions] watch:subscriptions", {
        subs,
        closureIds,
      });

      if (closureIds.length === 0) {
        this.closures = [];
        return;
      }

      const q = query(
        collection(db, "closures"),
        where(documentId(), "in", closureIds.slice(0, 3)) // limited to top 3
      );
      const snapshot = await getDocs(q);
      this.closures = manyFromSnapshot(snapshot);
    },
  },

  mounted() {
    if (this.user?.uid) this.subscribe();
  },

  methods: {
    subscribe() {
      this.unsubs.push(
        onSnapshot(
          doc(db, "subscriptions", this.user.uid),
          (snapshot) => {
            console.debug(
              "[DashboardCardUserSubscriptions] subscribe onSnapshot",
              snapshot
            );
            if (!snapshot.exists()) {
              this.subscriptions = [];
              return;
            }
            this.subscriptions = Object.values(snapshot.data()?.closures ?? {});
          },
          (e) => {
            this.errorToast(`Failed to sub to your closure subscriptions: ${e.message}`);
          }
        )
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
