export function oneFromSnapshot(snapshot) {
  if (!snapshot.exists()) return null;
  return {
    ...snapshot.data(),
    id: snapshot.id,
  };
}

export function manyFromSnapshot(snapshot) {
  if (snapshot.size === 0) return [];
  const arr = [];
  snapshot.docs.forEach((d) => {
    arr.push({ ...d.data(), id: d.id });
  });
  return arr;
}