<template>
  <help-more-info v-bind="$attrs" learn-more-url="/help/socials-content-generation">
    <p>
      With the help of AI, Clozure will draft a post for you to share on social media.
    </p>
    <p>
      To begin, create a task and add any closures to it that you would like to share.
      There are some handy presets to get you started.
    </p>
    <p>
      Once you're done adding closures, click Generate Content. This process can take a
      few minutes.
    </p>
    <p>
      When the task finishes you will have access to a map with labelled markers, and a
      summary of closure info.
    </p>
    <p>
      <strong>Please note:</strong> Content generation is currently limited to
      {{ closureLimit }} closures per single task. This is an external limitation that we
      are looking to overcome in a future update.
    </p>
  </help-more-info>
</template>

<script>
import { CONTENT_GENERATION_TASK_CLOSURE_LIMIT } from "clozure-shared/socials";
export default {
  name: "help-social-content-generation",

  computed: {
    closureLimit() {
      return CONTENT_GENERATION_TASK_CLOSURE_LIMIT;
    },
  },
};
</script>

<style lang="scss" scoped></style>
