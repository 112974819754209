<template>
  <DashboardCard>
    <template #title>
      Social Content Generation Tasks
      <HelpSocialContentGeneration triggers="click blur" />
    </template>
    <template #header-right>
      <b-badge variant="primary" v-if="taskCount > 0"> {{ taskCount }}</b-badge>
    </template>
    <b-list-group flush>
      <b-list-group-item
        v-for="(task, i) in tasks"
        :key="i"
        :to="`/comms/tasks/${task.id}`"
        class="d-flex justify-content-between align-items-center"
        style="gap: 15px"
      >
        <div>{{ task.name }}</div>
        <TaskStatusBadge class="text-badge" style="" :status="task.status" />
      </b-list-group-item>
      <b-list-group-item v-if="tasks?.length === 0">
        There are no social media content generation tasks.
      </b-list-group-item>
      <b-list-group-item to="/comms/tasks" class="text-center text-muted">
        View All
      </b-list-group-item>
    </b-list-group>
  </DashboardCard>
</template>

<script>
import DashboardCard from "@/components/DashboardCard";
import DashboardStaffCard from "@/mixins/DashboardStaffCard";
import { db } from "@/firebase/config";
import {
  query,
  where,
  onSnapshot,
  collection,
  orderBy,
  limit,
  getCountFromServer,
} from "firebase/firestore";
import { manyFromSnapshot } from "@/firebase/firestore-helpers";
import TaskStatusBadge from "@/components/TaskStatusBadge";
import HelpSocialContentGeneration from "@/components/HelpSocialContentGeneration";

export default {
  name: "dashboard-card-lga-recent-social-tasks",

  components: {
    DashboardCard,
    TaskStatusBadge,
    HelpSocialContentGeneration,
  },

  mixins: [DashboardCard, DashboardStaffCard],

  data: () => ({
    taskCount: 0,
    tasks: [],
  }),

  methods: {
    subscribe() {
      this.subMostRecentFive();
    },

    async refreshCount() {
      try {
        const colRef = collection(db, "content_generation_tasks");
        const q = query(colRef, where("lgaCode", "==", this.lgaCode));
        const snapshot = await getCountFromServer(q);
        this.taskCount = snapshot.data().count;
      } catch (e) {
        console.error(`Failed to refresh task count: ${e.message}`);
        this.taskCount = 0;
      }
    },

    subMostRecentFive() {
      const colRef = collection(db, "content_generation_tasks");

      const q = query(
        colRef,
        where("lgaCode", "==", this.lgaCode),
        orderBy("created", "desc"),
        limit(5)
      );

      const onSnap = async (snapshot) => {
        this.tasks = manyFromSnapshot(snapshot).map((task) => {
          if (task.error) task.status = "Error";
          else if (!task.initiated) task.status = "Draft";
          else if (!task.completed) task.status = "Generating";
          else if (!task.approved) task.status = "Awaiting Approval";
          else if (task.approved) task.status = "Approved";
          return task;
        });
        await this.refreshCount();
      };

      const onError = (e) => {
        this.errorToast(e.message);
      };

      this.unsubs.push(onSnapshot(q, onSnap, onError));
    },
  },
};
</script>

<style lang="scss" scoped>
.text-badge {
  letter-spacing: 0.06rem;
  text-transform: uppercase;
  padding: 7px 10px;
}
</style>
