import markerSDK from '@marker.io/browser';
import pkg from "../../../package.json";

const DEFAULT_CUSTOM_DATA = {
    env: process.env.VUE_APP_ENV,
    version: pkg.version,
};

const state = {
    widget: null,
    reporter: null,
    customData: DEFAULT_CUSTOM_DATA,
};

const mutations = {
    SET_WIDGET(state, widget) {
        state.widget = widget;
    },

    SET_REPORTER(state, reporter) {
        if (reporter) {
            const { email, displayName } = reporter;
            state.reporter = { email, fullName: displayName };
        } else {
            state.reporter = null;
        }
        state.widget?.setReporter?.(state.reporter || {});
    },

    SET_CUSTOM_DATA(state, customData) {
        state.customData = { ...(state.customData || {}), ...customData };
        state.widget?.setCustomData?.(state.customData);
    },

    CLEAR_CUSTOM_DATA(state) {
        state.customData = DEFAULT_CUSTOM_DATA;
    },
};

const actions = {
    async initialise({ commit, state }) {
        const widget = await markerSDK.loadWidget({
            project: '6471d788bfc98080a6400712',
        });
        widget.setCustomData(state.customData);
        commit("SET_WIDGET", widget);
    },

    hideMarkerIoWidget({ state }) {
        if (!state.widget) return;
        state.widget.hide();
    },

    showMarkerIoWidget({ state }) {
        if (!state.widget) return;
        state.widget.show();
    },

    async setReporter({ commit }, reporter) {
        commit("SET_REPORTER", reporter);
    },

    async setCustomData({ commit }, customData) {
        commit("SET_CUSTOM_DATA", customData);
    },

    async clearCustomData({ commit }) {
        commit("CLEAR_CUSTOM_DATA");
    },
};

const getters = {
    widget: (state) => state.widget,
    reporter: (state) => state.reporter,
    customData: (state) => state.customData,
};

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced: true,
};