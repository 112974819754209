import Vue from "vue";

export default {
    namespaced: true,

    state: {
        initialised: false,
        installed: false,
        deferredPrompt: null,
        rejected: null,
    },

    mutations: {
        SET_REJECTED(state, payload) {
            Vue.set(state, 'rejected', payload);
        },
        SET_INSTALLED(state, payload) {
            Vue.set(state, 'installed', payload);
        },
        SET_DEFERRED_PROMPT(state, payload) {
            Vue.set(state, 'deferredPrompt', payload);
        },
        MARK_INITIALISED(state) {
            Vue.set(state, 'initialised', true);
        },
    },

    actions: {
        async initialise({ state, commit }, window) {
            // if already initialised, do naught
            if (state.initialised) return;
            // bail out now if browser doesn't support this
            if (!("onbeforeinstallprompt" in window)) {
                console.warn("beforeinstallprompt is not supported");
                commit("MARK_INITIALISED");
                return;
            }
            // initialised window event listeners
            window.addEventListener("beforeinstallprompt", (e) => {
                // firefox still does not support this, so we don't want to preventDefault there
                e.preventDefault();
                commit("SET_DEFERRED_PROMPT", e);
            });
            window.addEventListener("appinstalled", () => {
                commit("SET_INSTALLED", true);
            });
            commit("MARK_INITIALISED");
        },

        async installPWA({ state, commit }) {
            if (!state.deferredPrompt) {
                throw new Error("PWA is already installed");
            }
            state.deferredPrompt.prompt();
            const { outcome } = await state.deferredPrompt.userChoice;
            commit("SET_REJECTED", outcome !== 'accepted');
            commit("SET_DEFERRED_PROMPT", null);
        },
    },

    getters: {
        pwaDeferredPromptAvailable: (state) => Boolean(state.deferredPrompt),
        pwaInitialised: (state) => state.initialised,
        pwaInstalled: (state) => state.initialised && state.installed,
        pwaInstallAvailable: (state) => Boolean(state.deferredPrompt && !state.installed),
    },

};