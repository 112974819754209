<script>
export default {
  name: "mobile-view",

  data: () => ({
    // window size
    windowInnerWidth: null,
  }),

  methods: {
    onWindowResize() {
      this.windowInnerWidth = window.innerWidth;
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    },
  },

  computed: {
    isMobile() {
      const mobileUserAgentStrings = ["ipad", "tablet", "mobile", "android"];
      const uaString = navigator.userAgent.toLowerCase();
      if (mobileUserAgentStrings.some((str) => uaString.includes(str))) return true;
      if (this.windowInnerWidth <= 768) return true;
      return false;
    },

    isDemo() {
      return process.env.VUE_APP_ENV === "demo";
    },

    isProduction() {
      return process.env.VUE_APP_ENV === "production";
    },
  },

  mounted() {
    // make map fit mobile view well
    this.onWindowResize();
    window.addEventListener("resize", () => this.onWindowResize());
  },
};
</script>
